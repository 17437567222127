/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Store
import { actions } from 'core/store'

// Constants
import { MODULE_ID_MAPPING } from 'common/constants/formOptions'

// Styled Elements
import { Wrapper, Container } from './AddressesTableItemDetails.elements'

// Hooks
import { useFetchAccount, useFetchRecipient, useGetNote } from 'core/hooks/api'

// Layouts
import { BlockchainAnalyticsItemDetailsSubHeader } from 'views/layouts'

// Internal Components
import { DetailsSummary, LinkedAccount, BusinessNotes } from './ItemDetailsComponents'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AddressesTableItemDetails(props) {
  // Destructure
  const {
    isExpanded,
    financialData,
    setFinancialData,
    riskData,
    setRiskData,
    addressData,
    setAddressData,
    accountDataProp,
    setAccountData,
    businessNotes,
    setBusinessNotes,
  } = props

  // Hooks
  const { getAccount, accountData, accountError, isAccountLoading } = useFetchAccount()
  const { getRecipient, recipientData, recipientError, isRecipientLoading } = useFetchRecipient()
  const { getNote, getNoteData, getNoteError, isGetNoteLoading } = useGetNote()

  // Local state
  const [page, setPage] = useState(0)

  // Functions
  const fetchData = () => {
    if (addressData.link_type === 1) getAccount({ account_id: addressData.link_id })
    else if (addressData.link_type === 2) getRecipient({ recipient_id: addressData.link_id })
    getNote({
      module_id: MODULE_ID_MAPPING.address,
      start_range: addressData.create_tz,
      end_range: moment().format('YYYY-MM-DD'),
      page: 0,
      size: 100,
    })
  }
  const handleData = () => {
    if (accountData) setAccountData(accountData)
    if (recipientData) setAccountData(recipientData)
    if (getNoteData) setBusinessNotes(getNoteData)
  }

  // UseEffect
  useEffect(() => handleData(), [accountData, recipientData, getNoteData])
  useEffect(() => addressData.link_id && isExpanded && !accountDataProp && fetchData(), [isExpanded])
  return (
    <Wrapper>
      <Container>
        <BlockchainAnalyticsItemDetailsSubHeader page={page} setPage={setPage} />
        {page === 0 && (
          <DetailsSummary
            key={riskData}
            isExpanded={isExpanded}
            financialData={financialData}
            setFinancialData={setFinancialData}
            riskData={riskData}
            setRiskData={setRiskData}
            addressData={addressData}
            setAddressData={setAddressData}
            accountData={accountData}
            setAccountData={setAccountData}
          />
        )}
        {page === 1 && (
          <LinkedAccount
            accountDataProp={accountDataProp}
            setAccountDataProp={setAccountData}
            address_data={addressData}
            isExpanded={isExpanded}
            isLoading={isAccountLoading || isRecipientLoading}
            isFetchError={accountError || recipientError}
          />
        )}
        {page === 2 && (
          <BusinessNotes
            address_data={addressData}
            isExpanded={isExpanded}
            businessNotes={businessNotes}
            setBusinessNotes={setBusinessNotes}
            isLoading={isGetNoteLoading}
            isFetchError={getNoteError}
          />
        )}
      </Container>
    </Wrapper>
  )
}

// Default Props
AddressesTableItemDetails.defaultProps = {
  financialData: null,
  setFinancialData: () => {},
  riskData: null,
  setRiskData: () => {},
  addressData: null,
  setAddressData: () => {},
  actions: {},
  filters: {},
  ui: {},
  isExpanded: false,
}

// Proptypes Validation
AddressesTableItemDetails.propTypes = {
  financialData: PropTypes.instanceOf(Object),
  setFinancialData: PropTypes.func,
  riskData: PropTypes.instanceOf(Object),
  setRiskData: PropTypes.func,
  addressData: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    create_tz: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
    modified_by_name: PropTypes.string,
  }),
  accountDataProp: PropTypes.shape({}),
  setAccountData: PropTypes.func,
  businessNotes: PropTypes.arrayOf({}),
  setBusinessNotes: PropTypes.func,
  setAddressData: PropTypes.func,
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setAddressDetails: PropTypes.func,
  }),

  isExpanded: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressesTableItemDetails)
