/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  Wrapper,
  HeaderWrapper,
  LeftGroupWrapper,
  LeftGroupItem,
  LeftGroupItemWrapper,
  RightGroupWrapper,
  RightGroupItem,
  RightGroupItemWrapper,
} from './VerticalDotProgress.elements'

function VerticalDotProgress(props) {
  // Destructure
  const { headers } = props
  console.log(headers)
  return (
    <Wrapper>
      <HeaderWrapper>
        <LeftGroupWrapper>
          {headers.map(({ leftLabel }, i) => (
            <LeftGroupItemWrapper key={i}>
              <LeftGroupItem>{leftLabel && leftLabel}</LeftGroupItem>
            </LeftGroupItemWrapper>
          ))}
        </LeftGroupWrapper>
        <RightGroupWrapper>
          {headers.map(({ rightLabel, leftLabel, disabled, failed }, i) => (
            <RightGroupItemWrapper failed={failed} disabled={disabled} active={leftLabel !== null} key={i}>
              <RightGroupItem disabled={disabled}>{rightLabel && rightLabel}</RightGroupItem>
            </RightGroupItemWrapper>
          ))}
        </RightGroupWrapper>
      </HeaderWrapper>
    </Wrapper>
  )
}

// Default Props
VerticalDotProgress.defaultProps = {
  headers: {},
}

// Proptypes Validation
VerticalDotProgress.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      leftLabel: PropTypes.string,
      rightLabel: PropTypes.string,
      disabled: PropTypes.bool,
      failed: PropTypes.bool,
      success: PropTypes.bool,
    })
  ),
}

export default VerticalDotProgress
