import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TransferFormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormWrapper,
  FormLabel,
  OptionLabel,
} from './LinkAddressForm.elements'

// Hooks
import { useSearchAccount, useFetchAccount } from 'core/hooks/api'

// Views
import { TextField, Button, Search } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function LinkAddressForm(props) {
  // Destructure
  const { formData, setFormData, actions, ui } = props

  // Store
  const { isLinkAddressDrawerOpen } = ui
  const {
    setIsIndividualAccountDrawerOpen,
    setIsEntityAccountDrawerOpen,
    setShowHeaderLoader,
    setToBeUpdatedRecipient,
  } = actions

  // hooks
  const { searchAccount, accountSearchData, isAccountSearchLoading } = useSearchAccount()
  const { getAccount, accountData, isAccountLoading } = useFetchAccount()

  // Internal State
  const [searchResults, setSearchResults] = useState([])

  // functions
  const handleAccountSearch = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value) return searchAccount({ search_term: value })
  }, 500)
  const handleOnAutoCompleteSelect = (value) => {
    getAccount({ account_id: value })
  }

  // useEffects
  useEffect(() => {
    if (accountData) {
      setFormData({ ...formData, account: { ...formData.account, ...accountData } })
    }
  }, [accountData])
  useEffect(() => {
    if (accountSearchData) {
      setSearchResults(() =>
        accountSearchData.map(({ external_id, display_name, account_id }) => ({
          label: external_id,
          subLabel: display_name,
          value: account_id,
        }))
      )
    }
    if (!accountSearchData) setSearchResults([])
  }, [accountSearchData])
  useEffect(() => {
    if (isAccountLoading) {
      setShowHeaderLoader(true)
      setFormData({ ...formData, account: null })
    }
    if (!isAccountLoading) setShowHeaderLoader(false)
  }, [isAccountLoading])
  useEffect(() => !isLinkAddressDrawerOpen && setSearchResults([]), [isLinkAddressDrawerOpen])
  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormInputGroupItem>
            <Search
              placeholder="Search for account"
              onInputChange={handleAccountSearch}
              options={searchResults}
              setOptions={setSearchResults}
              onSelect={handleOnAutoCompleteSelect}
              isLoading={isAccountSearchLoading}
              bottomOptions={
                <FormGroupWrapper>
                  <OptionLabel>Create New Account</OptionLabel>
                  <FormInputGroupItem>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setToBeUpdatedRecipient(null)
                        setIsIndividualAccountDrawerOpen(true)
                      }}
                    >
                      Individual
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setToBeUpdatedRecipient(null)
                        setIsEntityAccountDrawerOpen(true)
                      }}
                    >
                      Business
                    </Button>
                  </FormInputGroupItem>
                </FormGroupWrapper>
              }
            />
          </FormInputGroupItem>

          {formData.account && !isAccountLoading && (
            <>
              <FormLabel>Selected Account</FormLabel>
              <FormInputGroupItem>
                <TextField label="External ID" disabled value={formData.account.external_id} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Account Type" disabled value={formData.account.account_type_desc} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Name" disabled value={formData.account.display_name} />
              </FormInputGroupItem>
            </>
          )}
          {isAccountLoading && <FormLabel>Loading...</FormLabel>}
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
LinkAddressForm.defaultProps = {
  ui: {},
  formData: {},
  setFormData: () => {},
  actions: {},
  form: {},
}

// Proptypes Validation
LinkAddressForm.propTypes = {
  ui: PropTypes.shape({ isLinkAddressDrawerOpen: PropTypes.bool }),
  formData: PropTypes.shape({
    transfer: PropTypes.shape({
      originator_hash_address: PropTypes.string,
    }),
    account: PropTypes.shape({
      external_id: PropTypes.string,
      account_type_desc: PropTypes.string,
      display_name: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdAccount: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkAddressForm)
