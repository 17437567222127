/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Assets
import { PlusWhiteIcon, CloseIcon, LinkIcon, EyeIcon, HelpIcon } from 'assets/images'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'
import {
  IP_RISK_PARTNER_OPTIONS,
  SUPPORTED_CHAIN_OPTIONS,
  IP_RISK_PARTNER_OPTIONS_MAPPING,
  RISK_OPTIONS,
} from 'common/constants/formOptions'

// Hooks
import { useGetAddressDetails, useCreateOrUpdateAddress } from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  ColoredText,
  Title,
  ColWrapper,
  ColContainer,
  RowWrapper,
  RowContainer,
  GridRowContainer,
  TextRowContainer,
  GroupRowContainer,
  BlockContainer,
  FlowWrapper,
  InfoTitle,
} from '../../AddressesTableItemDetails.elements'

// Components
import {
  // MoneyBar,
  Button,
  // NumberedChart,
  CircleProgressBar,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  LoadingTablePlaceholder,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DetailsSummary(props) {
  // Destructure
  const {
    actions,
    isExpanded,
    financialData,
    setFinancialData,
    riskData,
    setRiskData,
    addressData,
    setAddressData,
    accountData,
  } = props

  const {
    toggleAddressHistoryModal,
    toggleAddressReportModal,
    setAddressReportDetails,
    toggleDeleteAddressModal,
    setIsLinkAddressDrawerOpen,
    showAlert,
    setAddressLinkDetails,
    setAddressHistoryFilters,
    setAddressRemove,
    toggleInfoModal,
    setInfoModalDetails,
  } = actions

  // hooks
  const { getAddressDetails, getAddressDetailsData, getAddressDetailsError, isGetAddressDetailsLoading } =
    useGetAddressDetails()
  const {
    createOrUpdateAddress,
    createOrUpdateAddressData,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
  } = useCreateOrUpdateAddress()

  // local state
  // const [sentBarWidth, setSentBarWidth] = useState('0%')
  // const [receivedBarWidth, setReceivedBarWidth] = useState('0%')
  const [currentIpUsed, setCurrentIpUsed] = useState()
  // const [showDescriptors, setShowDescriptors] = useState(false)

  // !THIS FUNCTION RELLIES ON FINANCIAL DATA
  // const formatBarWidth = () => {
  //   const spentPercentage = (financialData.spent / (financialData.spent + financialData.received)) * 100
  //   const receivedPercentage = (financialData.received / (financialData.received + financialData.spent)) * 100
  //   setReceivedBarWidth(`${Math.floor(receivedPercentage)}%`)
  //   setSentBarWidth(`${Math.floor(spentPercentage)}%`)
  // }
  const formatVaspNames = (vaspName) => {
    let nameArray = vaspName.split(',').join(', ').split('_').join(' ')
    if (nameArray[nameArray.length - 2] === ',') {
      nameArray = nameArray.substring(0, nameArray.length - 2)
    }
    return nameArray
  }
  const handleDeleteAddress = () => {
    setAddressRemove({ address_id: addressData.id })
    toggleDeleteAddressModal()
  }
  const handleLink = () => {
    setAddressLinkDetails({
      address: addressData.address,
      address_id: addressData.id,
      chain: addressData.chain,
    })
    setIsLinkAddressDrawerOpen(true)
  }
  const handleScan = (ip) => {
    setCurrentIpUsed(ip)
    createOrUpdateAddress({
      address: addressData.address,
      chain: addressData.chain,
      integration_id: ip,
    })
  }
  const handleCreateReport = () => {
    setAddressReportDetails({
      address: addressData.address,
      address_id: addressData.id,
      chain: addressData.chain,
    })
    toggleAddressReportModal()
  }
  const handleHistoryToggle = () => {
    setAddressHistoryFilters({
      address: addressData.address,
      address_id: addressData.id,
      sort: '-modify_tz',
      page: 1,
      size: 10,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })
    toggleAddressHistoryModal()
  }
  const handleCreateOrUpdateAddress = () => {
    if (createOrUpdateAddressData) {
      setRiskData((prevValue) => {
        let newRiskData = prevValue
        let found = false
        prevValue.forEach((item, i) => {
          if (item.provider_name?.toLowerCase() === IP_RISK_PARTNER_OPTIONS_MAPPING[currentIpUsed]?.toLowerCase()) {
            newRiskData[i] = createOrUpdateAddressData.risk_data
            found = true
          }
        })
        if (found === false) newRiskData = [createOrUpdateAddressData.risk_data, ...newRiskData]
        return newRiskData
      })
      setFinancialData(createOrUpdateAddressData.financial_data)
      setAddressData({
        ...addressData,
        risk_score: createOrUpdateAddressData.risk_data.risk_score,
        balance_usd: createOrUpdateAddressData.financial_data.balance_usd,
        balance: createOrUpdateAddressData.financial_data.balance,
      })
    }
  }
  const handleToggleInfo = (title, content) => {
    setInfoModalDetails({ title, content })
    toggleInfoModal(true)
  }

  useEffect(() => {
    if (isExpanded && !financialData && !riskData) getAddressDetails({ address_id: addressData.id })
  }, [isExpanded])
  // useEffect(() => financialData && formatBarWidth(), [financialData])
  useEffect(() => {
    if (getAddressDetailsData) {
      setAddressData({ ...addressData, modified_by_name: getAddressDetailsData?.modified_by_name })
      setRiskData(getAddressDetailsData.risk_data)
      setFinancialData(getAddressDetailsData.financial_data)
    }
  }, [getAddressDetailsData])
  useEffect(() => handleCreateOrUpdateAddress(), [createOrUpdateAddressData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getAddressDetailsError) showAlert({ type: 'error', message: 'An error occured in fetching addresses' })
  }, [getAddressDetailsError])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (createOrUpdateAddressError) showAlert({ type: 'error', message: 'An error occured in scanning addresses' })
  }, [createOrUpdateAddressError])

  return (
    <FlowWrapper key={createOrUpdateAddressData || currentIpUsed}>
      {isGetAddressDetailsLoading && <LoadingTablePlaceholder />}
      {/* -------FINANCIAL DATA------- */}
      {financialData && !isGetAddressDetailsLoading && (
        <RowWrapper>
          <GridRowContainer>
            <BlockContainer>
              <TextRowContainer>
                <Text>Balance: </Text>
                <Text>
                  {numberWithCommas(Math.round(financialData.balance * 100) / 100)}{' '}
                  {addressData?.symbol && addressData.symbol.toUpperCase()}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Protocol: </Text>
                <Text>{addressData?.chain && addressData.chain}</Text>
              </TextRowContainer>
              {/* <TextRowContainer style={{ border: 'none' }}>
                <ColContainer>
                  <GroupRowContainer>
                    <Text style={{ minWidth: '70px' }}>Sent: </Text>
                    <MoneyBar style={{ maxWidth: 140 }} width={sentBarWidth} />
                    <Text style={{ minWidth: '140px', textAlign: 'right' }}>
                      {financialData?.spent
                        ? `${numberWithCommas(Math.round(financialData.spent * 100) / 100)} ${
                            addressData?.symbol && addressData.symbol.toUpperCase()
                          }`
                        : '-'}
                    </Text>
                  </GroupRowContainer>
                  <GroupRowContainer>
                    <Text style={{ minWidth: '70px' }}>Received: </Text>
                    <MoneyBar style={{ maxWidth: 140 }} width={receivedBarWidth} />
                    <Text style={{ minWidth: '140px', textAlign: 'right' }}>
                      {financialData?.received
                        ? `${numberWithCommas(Math.round(financialData.received * 100) / 100)} ${
                            addressData?.symbol && addressData.symbol.toUpperCase()
                          }`
                        : '-'}
                    </Text>
                  </GroupRowContainer>
                </ColContainer>
              </TextRowContainer> */}
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <Text>Travel Rule Transfers: </Text>
                <Text>{accountData?.open_transfers ? accountData.open_transfers : 0}</Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Detection Engine Alerts: </Text>
                <Text>{0}</Text>
              </TextRowContainer>
              {/* <TextRowContainer>
                <Text>Create Date: </Text>
                <Text>
                  {financialData?.latest_activity ? moment(financialData.latest_activity).format('YYYY-MM-DD') : '-'}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Latest Activity: </Text>
                <Text>
                  {financialData?.latest_activity ? moment(financialData.latest_activity).format('YYYY-MM-DD') : '-'}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Transactions: </Text>
                <NumberedChart activeNumber={financialData?.transaction_count && financialData.transaction_count} />
              </TextRowContainer> */}
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <Text>Linked Account Risk Level: </Text>
                <Text>
                  {accountData?.account_risk_value ? RISK_OPTIONS[accountData.account_risk_value]?.label : '-'}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Modified By: </Text>
                <Text> {addressData?.modified_by_name ? addressData.modified_by_name : '-'}</Text>
              </TextRowContainer>
            </BlockContainer>
          </GridRowContainer>
        </RowWrapper>
      )}

      {riskData &&
        isCreateOrUpdateAddressLoading &&
        riskData.find(
          (data) => data.provider_name?.toLowerCase() === IP_RISK_PARTNER_OPTIONS_MAPPING[currentIpUsed]?.toLowerCase()
        ) === undefined && (
          <RowContainer style={{ paddingTop: 10 }}>
            <BlockContainer style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
              <LoadingTablePlaceholder style={{ padding: '45px 0' }} />
            </BlockContainer>
          </RowContainer>
        )}
      {/* -------RISK DATA------- */}
      {riskData &&
        !isGetAddressDetailsLoading &&
        riskData.map((data) => (
          <RowWrapper key={data.id}>
            {isCreateOrUpdateAddressLoading &&
              data.provider_name?.toLowerCase() === IP_RISK_PARTNER_OPTIONS_MAPPING[currentIpUsed]?.toLowerCase() && (
                <RowContainer>
                  <BlockContainer style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
                    <LoadingTablePlaceholder style={{ padding: '45px 0' }} />
                  </BlockContainer>
                </RowContainer>
              )}
            {(!isCreateOrUpdateAddressLoading ||
              data.provider_name?.toLowerCase() !== IP_RISK_PARTNER_OPTIONS_MAPPING[currentIpUsed]?.toLowerCase()) && (
              <>
                <RowContainer style={{ maxWidth: '270px', alignItems: 'center' }}>
                  <BlockContainer style={{ display: 'flex', padding: 0 }}>
                    <GroupRowContainer style={{ gap: '0', alignItems: 'flex-start' }}>
                      <CircleProgressBar
                        style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}
                        width={data.risk_ratio && data.risk_ratio}
                      >
                        <Text style={{ fontSize: '18px', fontWeight: 600 }}>
                          {data.risk_ratio && `${data.risk_ratio}`}%
                        </Text>
                      </CircleProgressBar>
                      <RowContainer style={{ padding: '12px 0 0 0' }}>
                        <ColWrapper style={{ justifyContent: 'center', padding: '0 20px 0 0', height: '100%' }}>
                          <InfoTitle onClick={() => handleToggleInfo('Risk Score Information', data.risk_score_desc)}>
                            Risk Score
                            <HelpIcon />
                          </InfoTitle>
                          <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>
                            {`${data.risk_score} / ${data.max_score}`}
                          </Text>
                          <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                          <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>
                            {data.request_tz && moment(data.request_tz).format('YYYY-MM-DD')}
                          </Text>
                          <InfoTitle
                            onClick={() => handleToggleInfo('Risk Score Source Information', data.risk_partner_desc)}
                          >
                            Source
                            <HelpIcon />
                          </InfoTitle>
                          <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>
                            {data.provider_name && data.provider_name}
                          </Text>
                        </ColWrapper>
                      </RowContainer>
                    </GroupRowContainer>
                  </BlockContainer>
                </RowContainer>

                <RowContainer>
                  <BlockContainer>
                    <ColContainer>
                      <Title>Risk Score Descriptors: </Title>
                      <RowContainer style={{ gap: 5, maxHeight: '194px', overflowY: 'auto', paddingRight: '20px' }}>
                        {data.risk_descriptors?.length > 0 &&
                          data.risk_descriptors.map((descriptor, i) => (
                            <ColoredText style={{ padding: '2px 7px' }} key={i}>
                              {descriptor}
                            </ColoredText>
                          ))}
                        {/* {data.risk_descriptors?.length > 0 &&
                          data.risk_descriptors?.length < 7 &&
                          data.risk_descriptors.map((descriptor, i) => (
                            <ColoredText style={{ padding: '2px 7px' }} key={i}>
                              {descriptor}
                            </ColoredText>
                          ))}
                        {data.risk_descriptors?.length > 0 &&
                          data.risk_descriptors?.length >= 7 &&
                          data.risk_descriptors.map((descriptor, i) => (
                            <div key={i} style={{ display: i >= 6 ? (showDescriptors ? 'block' : 'none') : 'block' }}>
                              {i < 6 && (
                                <ColoredText style={{ padding: '2px 7px' }} key={i}>
                                  {descriptor}
                                </ColoredText>
                              )}
                              {i >= 6 && showDescriptors && (
                                <ColoredText style={{ padding: '2px 7px' }} key={i}>
                                  {descriptor}
                                </ColoredText>
                              )}
                            </div>
                          ))}
                        {data.risk_descriptors?.length > 0 && data.risk_descriptors?.length >= 7 && (
                          <div>
                            <ColoredText
                              onClick={() => setShowDescriptors(!showDescriptors)}
                              style={{ padding: '2px 7px', cursor: 'pointer' }}
                              color="darkGray"
                            >
                              {showDescriptors ? 'Show Less' : 'Show More'}
                            </ColoredText>
                          </div>
                        )} */}
                      </RowContainer>
                    </ColContainer>
                  </BlockContainer>
                </RowContainer>

                <RowContainer style={{ maxWidth: '443px' }}>
                  <BlockContainer>
                    <ColContainer>
                      <Title>Controlling Entity: </Title>
                      <TextRowContainer style={{ padding: '0 0 10px 0' }}>
                        <Text>Name: </Text>
                        <Text style={{ textAlign: 'right', textTransform: 'capitalize' }}>
                          {data.vasp_name_business ? formatVaspNames(data.vasp_name_business) : '-'}
                        </Text>
                      </TextRowContainer>
                      <TextRowContainer>
                        <Text>Website: </Text>
                        <Text style={{ textAlign: 'right', textTransform: 'lowercase' }}>
                          {data.vasp_website ? formatVaspNames(data.vasp_website) : '-'}
                        </Text>
                      </TextRowContainer>
                      <TextRowContainer>
                        <Text>Type: </Text>
                        <Text style={{ textAlign: 'right', textTransform: 'capitalize' }}>
                          {data.vasp_category ? formatVaspNames(data.vasp_category) : '-'}
                        </Text>
                      </TextRowContainer>
                    </ColContainer>
                  </BlockContainer>
                </RowContainer>
              </>
            )}
          </RowWrapper>
        ))}
      {/* -------BOTTOM BUTTONS------- */}
      <RowWrapper>
        <RowContainer>
          <Button variant="outlined" size="small" onClick={handleHistoryToggle}>
            See History
          </Button>
          <Button variant="outlined" size="small" onClick={handleCreateReport}>
            Create Report
          </Button>
        </RowContainer>
        <RowContainer style={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" size="small" endIcon={<CloseIcon />} onClick={handleDeleteAddress}>
            Remove
          </Button>
          <Button
            variant="outlined"
            size="small"
            endIcon={<EyeIcon />}
            onClick={() =>
              handleToggleInfo('Authorization Needed', 'You currently do not have access to this feature.')
            }
          >
            Investigate
          </Button>
          {addressData.id && (
            <Button variant="outlined" size="small" endIcon={<LinkIcon />} onClick={handleLink}>
              Link
            </Button>
          )}
          <UncontrolledDropdown direction="down">
            <DropdownToggle>
              <Button variant="outlined" size="small" endIcon={<PlusWhiteIcon />}>
                Add score
              </Button>
            </DropdownToggle>
            <DropdownMenu left style={{ maxWidth: '155px', minWidth: '125px', padding: '0 12px' }}>
              {IP_RISK_PARTNER_OPTIONS.map((item) => (
                <DropdownItem
                  key={item.value}
                  disabled={
                    isCreateOrUpdateAddressLoading ||
                    item.label === 'Crystal' ||
                    item.label === 'Ciphertrace' ||
                    !SUPPORTED_CHAIN_OPTIONS[item.label][addressData.chain]
                  }
                  onClick={() => {
                    if (
                      isCreateOrUpdateAddressLoading ||
                      item.label === 'Crystal' ||
                      item.label === 'Ciphertrace' ||
                      !SUPPORTED_CHAIN_OPTIONS[item.label][addressData.chain]
                    ) {
                      return
                    }
                    if (item.label === 'BIG' || item.label === 'Coinfirm') handleScan(item.value)
                    // else if (item.label === 'Crystal') handleScan(item.value)
                    else {
                      handleToggleInfo('Authorization Needed', 'You currently do not have access to this feature.')
                    }
                  }}
                  style={{ borderRadius: 0, justifyContent: 'center', padding: '9px' }}
                >
                  <Text>{item.label}</Text>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </RowContainer>
      </RowWrapper>
    </FlowWrapper>
  )
}

// Default Props
DetailsSummary.defaultProps = {
  financialData: null,
  setFinancialData: () => {},
  riskData: null,
  setRiskData: () => {},
  addressData: null,
  setAddressData: () => {},
  actions: {},
  filters: {},
  ui: {},
  isExpanded: false,
}

// Proptypes Validation
DetailsSummary.propTypes = {
  financialData: PropTypes.instanceOf(Object),
  setFinancialData: PropTypes.func,
  riskData: PropTypes.instanceOf(Object),
  setRiskData: PropTypes.func,
  accountData: PropTypes.instanceOf(Object),
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  addressData: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    modified_by_name: PropTypes.string,
  }),
  setAddressData: PropTypes.func,
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
    setAddressHistoryFilters: PropTypes.func,
    setAddressRemove: PropTypes.func,
    showAlert: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),

  address_data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
  }),
  isExpanded: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSummary)
