import React from 'react'
import {
  BitcoinMonoIcon,
  LitecoinMonoIcon,
  EthereumMonoIcon,
  BitcoinCashMonoIcon,
  BitcoinSvMonoIcon,
  CardanoMonoIcon,
  DashMonoIcon,
  NeoMonoIcon,
  PolkadotMonoIcon,
  RippleMonoIcon,
  SolanaMonoIcon,
  StellarMonoIcon,
  TezosMonoIcon,
  USDCMonoIcon,
  AlgorandMonoIcon,
  NearMonoIcon,
  DogecoinMonoIcon,
  PolygonMonoIcon,
} from 'assets/images/blockchainMonoSvgs'

export const BLOCKCHAIN_MONO_SVGS = {
  bitcoin: <BitcoinMonoIcon />,
  ethereum: <EthereumMonoIcon />,
  litecoin: <LitecoinMonoIcon />,
  'bitcoin-cash': <BitcoinCashMonoIcon />,
  'bitcoin-sv': <BitcoinSvMonoIcon />,
  cardano: <CardanoMonoIcon />,
  dash: <DashMonoIcon />,
  neo: <NeoMonoIcon />,
  polkadot: <PolkadotMonoIcon />,
  ripple: <RippleMonoIcon />,
  solana: <SolanaMonoIcon />,
  stellar: <StellarMonoIcon />,
  tezos: <TezosMonoIcon />,
  usdc: <USDCMonoIcon />,
  algorand: <AlgorandMonoIcon />,
  near: <NearMonoIcon />,
  doge: <DogecoinMonoIcon />,
  polygon: <PolygonMonoIcon />,
}
