/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { RISK_OPTIONS, YES_NO_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Styled Elements
import { FormWrapper, FormInputGroupItem, FormGroupWrapper, FormTitle } from './RiskDetailsForm.elements'

// Views
import { Form, FormTextArea, FormSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function RiskDetailsForm(props) {
  // Destructure
  const { formRef, values, viewMode } = props

  // Internal State
  const [initialData, setInitialData] = useState()
  const [isAccount, setIsAccount] = useState(true)

  // Variables
  const validationSchema = Yup.object().shape({
    account_risk_value: Yup.number(),
    recipient_risk_value: Yup.number(),
    pep_id: Yup.number(),
    sanction_id: Yup.number(),
    adverse_media_id: Yup.number(),
    comments: Yup.string(),
  })

  // UseEffects
  useEffect(() => {
    if (values.account_type_id) setIsAccount(true)
    if (values.recipient_type_id) setIsAccount(false)
    setInitialData(values)
  }, [values])
  return (
    <FormWrapper>
      {initialData && (
        <Form formRef={formRef} initialValues={initialData} validationSchema={validationSchema}>
          <FormTitle>Risk and Verification </FormTitle>
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormSelect
                disabled={viewMode}
                type="number"
                label={isAccount ? 'Account Risk' : 'Recipient Risk'}
                name={isAccount ? 'account_risk_value' : 'recipient_risk_value'}
                options={RISK_OPTIONS}
              />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect disabled={viewMode} type="number" label="PEP" name="pep_id" options={YES_NO_OPTIONS} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect
                disabled={viewMode}
                type="number"
                label="Sanction"
                name="sanction_id"
                options={YES_NO_OPTIONS}
              />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect
                disabled={viewMode}
                type="number"
                label="Adverse Media"
                name="adverse_media_id"
                options={YES_NO_OPTIONS}
              />
            </FormInputGroupItem>
          </FormGroupWrapper>

          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextArea disabled={viewMode} label="Comments" name="comments" rows="4" />
            </FormInputGroupItem>
          </FormGroupWrapper>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
RiskDetailsForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    account_risk_value: 0,
    pep_id: '',
    sanction_id: '',
    adverse_media_id: '',
    comments: '',
  },
  viewMode: true,
}

// Proptypes Validation
RiskDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    countryListOptions: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    account_risk_value: PropTypes.number,
    recipient_risk_value: PropTypes.number,
    account_type_id: PropTypes.number,
    recipient_type_id: PropTypes.number,
    pep_id: PropTypes.string,
    sanction_id: PropTypes.string,
    adverse_media_id: PropTypes.string,
    comments: PropTypes.string,
  }),
  viewMode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskDetailsForm)
