import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { COUNTRIES_LIST } from 'common/constants/countries'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useCreateRecipientIndividual, useUpdateRecipientIndividual } from 'core/hooks/api'

// Styled Elements
import {
  FormWrapper,
  FormContainer,
  FormInputGroupItem,
  FormHeader,
  FormTitle,
  FormFooter,
} from '../DirectoryForms.elements'

// Views
import { Form, FormTextField, FormSearchSelect, FormDatePicker, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function IndividualRecipientForm(props) {
  // Destructure
  const { form, actions } = props

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const {
    createRecipientIndividual,
    recipientIndividualCreateData,
    isRecipientIndividualCreateLoading,
    isRecipientIndividualCreateSuccess,
  } = useCreateRecipientIndividual()
  const {
    updateRecipientIndividual,
    recipientIndividualUpdateData,
    isRecipientIndividualUpdateLoading,
    isRecipientIndividualUpdateSuccess,
  } = useUpdateRecipientIndividual()

  // Store State
  const { toBeUpdatedRecipient } = form

  // Store Actions
  const {
    setIsIndividualRecipientDrawerOpen,
    setToBeUpdatedRecipient,
    showAlert,
    setShowHeaderLoader,
    setCreatedRecipient,
    setIsPageTableUpdated,
    setIsRecordUpdated,
  } = actions

  // Internal State
  const [isCreateRecipient, setIsCreateRecipient] = useState(true)
  const [initialData, setInitialData] = useState()

  // Variables
  const formRef = useRef()
  const initialValues = {
    external_id: '',
    email: '',
    name_first: '',
    name_middle: '',
    name_last: '',
    dob: '',
    nationality_code: '',
    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  }

  const validationSchema = Yup.object().shape({
    external_id: Yup.string().required('Customer Id is required'),
    email: Yup.string().email().required('Email is required'),
    name_first: Yup.string().required('First Name is required'),
    name_middle: Yup.string().required('Middle Name is required'),
    name_last: Yup.string().required('Last Name is required'),
    dob: Yup.date().required('Birthdate is required'),
    nationality_code: Yup.string().required('Nationality is required'),
    address_street: Yup.string().required('Street is required'),
    address_city: Yup.string().required('City is required'),
    address_region: Yup.string().required('Region is required'),
    address_postcode: Yup.string().required('Zipcode is required'),
    address_country_code: Yup.string().required('Country is required'),
  })

  // Functions
  const triggerSubmit = () => {
    formRef.current.submitForm()
  }
  const handleOnSubmit = (values) => {
    const payload = {
      biz_id: userCredentials.Business_ID,
      ...values,
    }
    // removing empty fields on object
    Object.keys(payload).forEach((k) => payload[k] === '' && delete payload[k])
    if (isCreateRecipient) {
      createRecipientIndividual(payload)
    } else if (!isCreateRecipient) {
      updateRecipientIndividual({ recipient_id: toBeUpdatedRecipient.recipient_id, values: payload })
    }
  }

  const handleLoadingChange = () => {
    if (isRecipientIndividualCreateLoading || isRecipientIndividualUpdateLoading) {
      setShowHeaderLoader(true)
    }
    if (!isRecipientIndividualCreateLoading && !isRecipientIndividualUpdateLoading) {
      setShowHeaderLoader(false)
      if (initialData) {
        if (isCreateRecipient) {
          if (isRecipientIndividualCreateSuccess) {
            setCreatedRecipient(recipientIndividualCreateData)
            setIsIndividualRecipientDrawerOpen(false)
            setIsPageTableUpdated(true)
            showAlert({ type: 'success', message: 'Successfully created record' })
          } else if (!isRecipientIndividualCreateSuccess) {
            showAlert({ type: 'error', message: 'An error occured in creating record' })
          }
        } else if (!isCreateRecipient) {
          if (isRecipientIndividualUpdateSuccess) {
            setCreatedRecipient(recipientIndividualUpdateData)
            setToBeUpdatedRecipient(null)
            setIsIndividualRecipientDrawerOpen(false)
            setIsRecordUpdated(true)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isRecipientIndividualUpdateSuccess) {
            showAlert({ type: 'error', message: 'An error occured in updating record' })
          }
        }
      }
    }
  }
  // UseEffects
  useEffect(() => {
    if (!toBeUpdatedRecipient) {
      setIsCreateRecipient(true)
      setInitialData(initialValues)
    } else {
      setIsCreateRecipient(false)
      setInitialData(toBeUpdatedRecipient)
    }
  }, [])
  useEffect(() => handleLoadingChange(), [isRecipientIndividualCreateLoading, isRecipientIndividualUpdateLoading])

  return (
    <FormWrapper>
      {initialData && (
        <Form
          formRef={formRef}
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormHeader>
            <FormTitle>{isCreateRecipient ? 'Create Individual Recipient' : 'Edit Individual Recipient'}</FormTitle>
          </FormHeader>

          <FormContainer>
            <FormInputGroupItem>
              <FormTextField label="Customer ID e.g. ID0001" name="external_id" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Email" type="email" name="email" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="First Name" name="name_first" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Middle Name" name="name_middle" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Last Name" name="name_last" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormDatePicker label="Birthdate" name="dob" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect label="Nationality" name="nationality_code" options={COUNTRIES_LIST} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Street" name="address_street" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="City" name="address_city" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Region" name="address_region" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Zipcode" name="address_postcode" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect label="Country" name="address_country_code" options={COUNTRIES_LIST} />
            </FormInputGroupItem>
          </FormContainer>

          <FormFooter>
            <Button type="button" onClick={triggerSubmit}>
              {isCreateRecipient ? 'Create Individual Recipient' : 'Save Individual Recipient'}
            </Button>
          </FormFooter>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
IndividualRecipientForm.defaultProps = {
  formRef: {},
  caseCustomerData: {},
  form: {},
  actions: {},
}

// Proptypes Validation
IndividualRecipientForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  caseCustomerData: PropTypes.shape({ Customer_ID: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedRecipient: PropTypes.shape({
      recipient_id: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
    showAlert: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setCreatedRecipient: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setIsRecordUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualRecipientForm)
