import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { ACCOUNT_TYPE_OPTIONS, ACTIVITY_ENTITY_OPTIONS } from 'common/constants/formOptions'
import { COUNTRIES_LIST } from 'common/constants/countries'

// Store
import { actions } from 'core/store'

// Styled Elements
import { FormWrapper, FormInputGroupItem, FormGroupWrapper, FormTitle } from './RecipientEntityDetailsForm.elements'

// Views
import { Form, FormTextField, FormSearchSelect, FormSelect, FormDatePicker } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function RecipientEntityDetailsForm(props) {
  // Destructure
  const { values, viewMode, formRef } = props

  // Internal State
  const [initialData, setInitialData] = useState()

  // Variables
  const validationSchema = Yup.object().shape({
    external_id: Yup.string().nullable(),
    recipient_type_id: Yup.number().nullable(),
    email: Yup.string().email().nullable(),
    name_business: Yup.string().nullable(),
    name_legal: Yup.string().nullable(),
    inc_country_code: Yup.string().nullable(),
    inc_date: Yup.date().nullable(),
    primary_activity_code: Yup.number().nullable(),

    created: Yup.date().nullable(),
    last_modified: Yup.date().nullable(),

    address_street: Yup.string().nullable(),
    address_city: Yup.string().nullable(),
    address_region: Yup.string().nullable(),
    address_postcode: Yup.string().nullable(),
    address_country_code: Yup.string().nullable(),
  })

  // Functions

  // UseEffects
  useEffect(() => {
    setInitialData(values)
  }, [values])
  return (
    <FormWrapper>
      {initialData && (
        <Form formRef={formRef} initialValues={initialData} validationSchema={validationSchema}>
          <FormTitle>Business information</FormTitle>
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Account ID" name="external_id" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect disabled label="Account Type" name="recipient_type_id" options={ACCOUNT_TYPE_OPTIONS} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Email" type="email" name="email" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Business Name" name="name_business" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Legal Name" name="name_legal" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect
                disabled={viewMode}
                label="Country of Incorporation"
                name="inc_country_code"
                options={COUNTRIES_LIST}
              />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormDatePicker disabled={viewMode} label="Date of Incorporation" name="inc_date" />
            </FormInputGroupItem>
            {/* <FormInputGroupItem>
              <FormSelect
                disabled={viewMode}
                label="Primary Activity"
                name="primary_activity_code"
                options={ACTIVITY_ENTITY_OPTIONS}
              />
            </FormInputGroupItem> */}

            <FormInputGroupItem>
              <FormDatePicker disabled label="Created" name="created" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormDatePicker disabled label="Last Modified" name="last_modified" />
            </FormInputGroupItem>
          </FormGroupWrapper>

          <FormTitle>Registered address</FormTitle>
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Street" name="address_street" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="City" name="address_city" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Region" name="address_region" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Postcode" name="address_postcode" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect
                disabled={viewMode}
                label="Country"
                name="address_country_code"
                options={COUNTRIES_LIST}
              />
            </FormInputGroupItem>
          </FormGroupWrapper>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
RecipientEntityDetailsForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    recipient_type_id: 0,
    email: '',
    name_business: '',
    name_legal: '',
    incorporation_country: '',
    inc_date: '',
    primary_activity_code: 0,

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  },
  viewMode: true,
}

// Proptypes Validation
RecipientEntityDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    recipient_type_id: PropTypes.number,
    email: PropTypes.string,
    name_business: PropTypes.string,
    name_legal: PropTypes.string,
    incorporation_country: PropTypes.string,
    inc_date: PropTypes.string,
    primary_activity_code: PropTypes.number,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
  }),
  viewMode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientEntityDetailsForm)
