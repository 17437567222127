import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useGetReports } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './ReportsTable.elements'

// Views
import { Table, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { ReportsTableItem, ReportsTableHeader, ReportsTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function ReportsTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { reportsFilters } = filters
  const { isPageTableUpdated } = ui
  const { setIsPageTableUpdated, setReportsFilters, showAlert } = actions

  // Hooks
  const { getReports, getReportsData, getReportsError, isGetReportsLoading } = useGetReports()

  // Local states
  const [reports, setReports] = useState([])
  const [pagination, setPagination] = useState()

  useEffect(() => getReports(reportsFilters), [reportsFilters])
  useEffect(() => {
    if (isPageTableUpdated) {
      getReports(reportsFilters)
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])
  useEffect(() => {
    if (getReportsData) {
      setReports(getReportsData.items)
      setPagination(getReportsData.pagination)
    }
  }, [getReportsData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getReportsError) showAlert({ type: 'error', message: 'An error occured in fetching reports' })
  }, [getReportsError])
  return (
    <TableWrapper>
      <Table
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        tableFilters={reportsFilters}
        setTableFilters={setReportsFilters}
      >
        <ReportsTableFilter />
        <ReportsTableHeader />
        {reports.length > 0 && !isGetReportsLoading ? (
          <>
            {reports.map((data) => (
              <ReportsTableItem key={data.id} data={data} />
            ))}
          </>
        ) : (
          <div>
            {isGetReportsLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <EmptyTablePlaceholder setFilter={setReportsFilters} />
            )}
          </div>
        )}
      </Table>
    </TableWrapper>
  )
}

// Default Props
ReportsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
ReportsTable.propTypes = {
  filters: PropTypes.shape({
    reportsFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setReportsFilters: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTable)
