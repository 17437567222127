import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { ACCOUNT_TYPE_OPTIONS, ACCOUNT_STATUS_OPTIONS, ACTIVITY_INDIVIDUAL_OPTIONS } from 'common/constants/formOptions'
import { COUNTRIES_LIST } from 'common/constants/countries'

// Store
import { actions } from 'core/store'

// Styled Elements
import { FormWrapper, FormInputGroupItem, FormGroupWrapper, FormTitle } from './AccountIndividualDetailsForm.elements'

// Views
import { Form, FormTextField, FormSelect, FormSearchSelect, FormDatePicker } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AccountIndividualDetailsForm(props) {
  // Destructure
  const { values, viewMode, formRef } = props

  // Internal State
  const [initialData, setInitialData] = useState()

  // Variables
  const validationSchema = Yup.object().shape({
    external_id: Yup.string().nullable(),
    account_type_id: Yup.number().nullable(),
    status_id: Yup.number().nullable(),
    email: Yup.string().email().nullable(),
    primary_activity_code: Yup.number().nullable(),
    nationality_code: Yup.string().nullable(),
    name_first: Yup.string().nullable(),
    name_middle: Yup.string().nullable(),
    name_last: Yup.string().nullable(),
    dob: Yup.date().nullable(),

    created: Yup.date().nullable(),
    last_modified: Yup.date().nullable(),

    address_street: Yup.string().nullable(),
    address_city: Yup.string().nullable(),
    address_region: Yup.string().nullable(),
    address_postcode: Yup.string().nullable(),
    address_country_code: Yup.string().nullable(),
  })

  // Functions

  // UseEffects
  useEffect(() => {
    setInitialData(values)
  }, [values])
  return (
    <FormWrapper>
      {initialData && (
        <Form formRef={formRef} initialValues={initialData} validationSchema={validationSchema}>
          <FormTitle>Personal information</FormTitle>
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Account ID" name="external_id" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect disabled label="Account Type" name="account_type_id" options={ACCOUNT_TYPE_OPTIONS} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect disabled={viewMode} label="Status" name="status_id" options={ACCOUNT_STATUS_OPTIONS} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Email" type="email" name="email" />
            </FormInputGroupItem>
            {/* <FormInputGroupItem>
              <FormSelect
                disabled={viewMode}
                label="Primary Activity / Profession"
                name="primary_activity_code"
                options={ACTIVITY_INDIVIDUAL_OPTIONS}
              />
            </FormInputGroupItem> */}
            <FormInputGroupItem>
              <FormSearchSelect
                disabled={viewMode}
                label="Nationality"
                name="nationality_code"
                options={COUNTRIES_LIST}
              />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="First Name" name="name_first" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Middle Name" name="name_middle" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Last Name" name="name_last" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormDatePicker disabled={viewMode} label="Date of Birth" name="dob" />
            </FormInputGroupItem>

            <FormInputGroupItem>
              <FormDatePicker disabled label="Created" name="created" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormDatePicker disabled label="Last Modified" name="last_modified" />
            </FormInputGroupItem>
          </FormGroupWrapper>

          <FormTitle>Personal address</FormTitle>
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Street" name="address_street" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="City" name="address_city" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Region" name="address_region" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Postcode" name="address_postcode" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect
                disabled={viewMode}
                label="Country"
                name="address_country_code"
                options={COUNTRIES_LIST}
              />
            </FormInputGroupItem>
          </FormGroupWrapper>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
AccountIndividualDetailsForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    account_type_id: 0,
    status_id: 0,
    email: '',
    primary_activity_code: 0,
    nationality_code: '',
    name_first: '',
    name_middle: '',
    name_last: '',
    dob: '',

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  },
  viewMode: true,
}

// Proptypes Validation
AccountIndividualDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    account_type_id: PropTypes.number,
    status_id: PropTypes.number,
    email: PropTypes.string,
    primary_activity_code: PropTypes.number,
    nationality_code: PropTypes.number,
    name_first: PropTypes.string,
    name_middle: PropTypes.string,
    name_last: PropTypes.string,
    dob: PropTypes.string,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
  }),
  viewMode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountIndividualDetailsForm)
