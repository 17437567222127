/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Assets
import DownloadIcon from 'assets/images/download'
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAccount, useUpdateAccountEntity, useUpdateAccountIndividual } from 'core/hooks/api'

// Styled Elements
import {
  Wrapper,
  Container,
  Title,
  Subtitle,
  ActivityWrapper,
  Row,
  RowHeader,
  Text,
  EditBtnWrapper,
  FooterBtnWrapper,
} from './AccountDetails.elements'

// Views
import { Button } from 'views/components'

import {
  DirectoryDetailsSubHeader,
  AccountIndividualDetailsForm,
  AccountEntityDetailsForm,
  RiskDetailsForm,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AccountDetails(props) {
  // Destructure
  const { actions } = props
  const { account_id } = useParams()

  // store actions
  const { setShowHeaderLoader, showAlert } = actions

  // refs
  const formRef = useRef()

  // hooks
  const { getAccount, accountData, isAccountLoading } = useFetchAccount()
  const { accountEntityUpdateData, updateAccountEntity, isAccountEntityUpdateLoading, isAccountEntityUpdateSuccess } =
    useUpdateAccountEntity()
  const {
    accountIndividualUpdateData,
    updateAccountIndividual,
    isAccountIndividualUpdateLoading,
    isAccountIndividualUpdateSuccess,
  } = useUpdateAccountIndividual()

  // state
  const [accountDataState, setAccountDataState] = useState()
  const [viewMode, setViewMode] = useState(true)
  const [fakeLoading, setFakeLoading] = useState(false)
  const [page, setPage] = useState(0)

  // functions
  const fetchData = () => {
    getAccount({ account_id })
  }
  const handleSave = () => {
    const newPayload = formRef.current.values
    if (!newPayload) return
    if (accountDataState.account_type_id === 1) {
      updateAccountIndividual({ account_id, values: newPayload })
    }
    if (accountDataState.account_type_id === 2) {
      updateAccountEntity({ account_id, values: newPayload })
    }
  }
  const handleCancel = () => {
    setViewMode(true)
    setFakeLoading(true)
    if (accountEntityUpdateData) return setAccountDataState(accountEntityUpdateData)
    if (accountIndividualUpdateData) return setAccountDataState(accountIndividualUpdateData)
    return setAccountDataState(accountData)
  }
  const handleLoadingUpdateChange = () => {
    if (isAccountEntityUpdateLoading || isAccountIndividualUpdateLoading) {
      setShowHeaderLoader(true)
    }
    if (!isAccountEntityUpdateLoading && !isAccountIndividualUpdateLoading) {
      setShowHeaderLoader(false)
      if (accountDataState) {
        if (accountDataState?.account_type_id === 1) {
          if (isAccountIndividualUpdateSuccess) {
            setAccountDataState(accountIndividualUpdateData)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isAccountIndividualUpdateSuccess) {
            handleCancel()
            showAlert({ type: 'error', message: 'An error occured in updating record' })
          }
        } else if (accountDataState?.account_type_id === 2) {
          if (isAccountEntityUpdateSuccess) {
            setAccountDataState(accountEntityUpdateData)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isAccountEntityUpdateSuccess) {
            handleCancel()
            showAlert({ type: 'error', message: 'An error occured in updating record' })
          }
        }
        setViewMode(true)
      }
    }
  }
  const handleLoadingAccount = () => {
    if (isAccountLoading) setShowHeaderLoader(true)
    if (!isAccountLoading) setShowHeaderLoader(false)
  }

  // useEffect
  useEffect(() => fetchData(), [])
  useEffect(() => handleCancel(), [page])
  useEffect(() => accountData && setAccountDataState(accountData), [accountData])
  useEffect(() => handleLoadingAccount(), [isAccountLoading])
  useEffect(() => fakeLoading && setFakeLoading(false), [fakeLoading])
  useEffect(() => {
    if (formRef?.current) handleLoadingUpdateChange()
  }, [isAccountEntityUpdateLoading, isAccountIndividualUpdateLoading])

  return (
    <Wrapper>
      {!isAccountLoading && accountDataState && (
        <>
          <Title>ID: {accountDataState.external_id}</Title>{' '}
          <Subtitle>{accountDataState.account_type_id === 1 ? 'INDIVIDUAL ACCOUNT' : 'BUSINESS ACCOUNT'}</Subtitle>
          <Container>
            <DirectoryDetailsSubHeader page={page} setPage={setPage} />
            {page === 0 && (
              <>
                {viewMode === true && (
                  <EditBtnWrapper>
                    <Button color="primary" onClick={() => setViewMode(false)}>
                      Edit
                    </Button>
                  </EditBtnWrapper>
                )}
                {accountDataState.account_type_id === 1
                  ? !fakeLoading && (
                      <AccountIndividualDetailsForm values={accountDataState} viewMode={viewMode} formRef={formRef} />
                    )
                  : !fakeLoading && (
                      <AccountEntityDetailsForm values={accountDataState} viewMode={viewMode} formRef={formRef} />
                    )}
                {viewMode === false && (
                  <FooterBtnWrapper>
                    <Button type="button" onClick={handleCancel} variant="outlined" startIcon={<CloseIcon />}>
                      Cancel
                    </Button>
                    <Button type="button" onClick={handleSave} startIcon={<DownloadIcon />}>
                      Save Changes
                    </Button>
                  </FooterBtnWrapper>
                )}
              </>
            )}

            {page === 1 && (
              <ActivityWrapper>
                <Row>
                  <RowHeader>Account Activity</RowHeader> <RowHeader>Value</RowHeader>
                </Row>
                <Row>
                  <Text>Pending Transfers</Text> <Text>{accountDataState.open_transfers}</Text>
                </Row>
                <Row>
                  <Text>Open Alerts</Text> <Text>{accountDataState.open_alerts}</Text>
                </Row>
              </ActivityWrapper>
            )}

            {page === 2 && (
              <>
                {viewMode === true && (
                  <EditBtnWrapper>
                    <Button color="primary" onClick={() => setViewMode(false)}>
                      Edit
                    </Button>
                  </EditBtnWrapper>
                )}
                {!fakeLoading && <RiskDetailsForm values={accountDataState} viewMode={viewMode} formRef={formRef} />}
                {viewMode === false && (
                  <FooterBtnWrapper>
                    <Button type="button" onClick={handleCancel} variant="outlined" startIcon={<CloseIcon />}>
                      Cancel
                    </Button>
                    <Button type="button" onClick={handleSave} startIcon={<DownloadIcon />}>
                      Save Changes
                    </Button>
                  </FooterBtnWrapper>
                )}
              </>
            )}
          </Container>
        </>
      )}
    </Wrapper>
  )
}

// Default Props
AccountDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
AccountDetails.propTypes = {
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
