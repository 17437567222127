import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { COUNTRIES_LIST } from 'common/constants/countries'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useCreateAccountEntity, useUpdateAccountEntity } from 'core/hooks/api'

// Styled Elements
import {
  FormWrapper,
  FormContainer,
  FormInputGroupItem,
  FormHeader,
  FormTitle,
  FormFooter,
} from '../DirectoryForms.elements'

// Views
import { Form, FormTextField, FormSearchSelect, FormDatePicker, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function EntityAccountForm(props) {
  // Destructure
  const { form, actions } = props

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const { createAccountEntity, accountEntityCreateData, isAccountEntityCreateLoading, isAccountEntityCreateSuccess } =
    useCreateAccountEntity()
  const { updateAccountEntity, accountEntityUpdateData, isAccountEntityUpdateLoading, isAccountEntityUpdateSuccess } =
    useUpdateAccountEntity()

  // Store State
  const { toBeUpdatedAccount } = form

  // Store Actions
  const {
    setIsEntityAccountDrawerOpen,
    setToBeUpdatedAccount,
    showAlert,
    setShowHeaderLoader,
    setCreatedAccount,
    setIsPageTableUpdated,
    setIsRecordUpdated,
  } = actions

  // Internal State
  const [isCreateAccount, setIsCreateAccount] = useState(true)
  const [initialData, setInitialData] = useState()

  // Variables
  const formRef = useRef()
  const initialValues = {
    external_id: '',
    email: '',
    name_legal: '',
    name_business: '',
    inc_country_code: 0,
    inc_date: '',
    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  }

  const validationSchema = Yup.object().shape({
    external_id: Yup.string().required('Customer Id is required'),
    email: Yup.string().email().required('Email is required'),
    name_legal: Yup.string().required('Legal Name is required'),
    name_business: Yup.string().required('Business Name is required'),
    inc_country_code: Yup.string().required('Country of Incorporation is required'),
    inc_date: Yup.date().required('Date of Incorporation is required'),
    address_street: Yup.string().required('Street is required'),
    address_city: Yup.string().required('City is required'),
    address_region: Yup.string().required('Region is required'),
    address_postcode: Yup.string().required('Zipcode is required'),
    address_country_code: Yup.string().required('Country is required'),
  })

  // Functions
  const triggerSubmit = () => {
    formRef.current.submitForm()
  }
  const handleOnSubmit = (values) => {
    const payload = {
      biz_id: userCredentials.Business_ID,
      ...values,
    }
    // removing empty fields on object
    Object.keys(payload).forEach((k) => payload[k] === '' && delete payload[k])
    if (isCreateAccount) {
      createAccountEntity(payload)
    } else if (!isCreateAccount) {
      updateAccountEntity({ account_id: toBeUpdatedAccount.account_id, values: payload })
    }
  }

  const handleLoadingChange = () => {
    if (isAccountEntityCreateLoading || isAccountEntityUpdateLoading) {
      setShowHeaderLoader(true)
    }
    if (!isAccountEntityCreateLoading && !isAccountEntityUpdateLoading) {
      setShowHeaderLoader(false)
      if (initialData) {
        if (isCreateAccount) {
          if (isAccountEntityCreateSuccess) {
            setCreatedAccount(accountEntityCreateData)
            setIsEntityAccountDrawerOpen(false)
            setIsPageTableUpdated(true)
            showAlert({ type: 'success', message: 'Successfully created record' })
          } else if (!isAccountEntityCreateSuccess) {
            showAlert({ type: 'error', message: 'An error occured in creating record' })
          }
        } else if (!isCreateAccount) {
          if (isAccountEntityUpdateSuccess) {
            setCreatedAccount(accountEntityUpdateData)
            setToBeUpdatedAccount(null)
            setIsEntityAccountDrawerOpen(false)
            setIsRecordUpdated(true)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isAccountEntityUpdateSuccess) {
            showAlert({ type: 'error', message: 'An error occured in updating record' })
          }
        }
      }
    }
  }
  // UseEffects
  useEffect(() => {
    if (!toBeUpdatedAccount) {
      setIsCreateAccount(true)
      setInitialData(initialValues)
    } else {
      setIsCreateAccount(false)
      setInitialData(toBeUpdatedAccount)
    }
  }, [])
  useEffect(() => handleLoadingChange(), [isAccountEntityCreateLoading, isAccountEntityUpdateLoading])
  return (
    <FormWrapper>
      {initialData && (
        <Form
          formRef={formRef}
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormHeader>
            <FormTitle>{isCreateAccount ? 'Create Entity Account' : 'Edit Entity Account'}</FormTitle>
          </FormHeader>

          <FormContainer>
            <FormInputGroupItem>
              <FormTextField label="Customer ID e.g. ID0001" name="external_id" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Email" type="email" name="email" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Legal Name" name="name_legal" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Business Name" name="name_business" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect label="Country of Incorporation" name="inc_country_code" options={COUNTRIES_LIST} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormDatePicker label="Date of Incorporation" name="inc_date" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Street" name="address_street" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="City" name="address_city" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Region" name="address_region" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Zipcode" name="address_postcode" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect label="Country" name="address_country_code" options={COUNTRIES_LIST} />
            </FormInputGroupItem>
          </FormContainer>

          <FormFooter>
            <Button type="button" onClick={triggerSubmit}>
              {isCreateAccount ? 'Create Entity Account' : 'Save Entity Account'}
            </Button>
          </FormFooter>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
EntityAccountForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
}

// Proptypes Validation
EntityAccountForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.shape({
      account_id: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsRecordUpdated: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setCreatedAccount: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityAccountForm)
