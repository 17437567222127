import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

// Constants
import { CHAIN_TYPE_SIMPLIFIED_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Hooks
import { useFetchAssetConversion } from 'core/hooks/api'

// Styled Elements
import {
  TransferFormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormWrapper,
  FormLabel,
  SubText,
} from './AmountTransferForm.elements'

// Views
import { TextField, SimpleSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AmountTransferForm(props) {
  // Destructure
  const { formData, setFormData } = props

  // Hooks
  const { getAssetConversion, assetConversionData, isAssetConversionLoading } = useFetchAssetConversion()

  // Internal State
  const [currencyConversion, setCurrencyConversion] = useState([''])
  const [isAmountUsdLoading, setIsAmountUsdLoading] = useState(false)

  // functions
  const debouncedFunctionRef = useRef()
  debouncedFunctionRef.current = () => {
    if (formData?.transfer?.amount && formData?.transfer?.asset_code) {
      getAssetConversion({ from_symbols: formData?.transfer?.asset_code, to_symbols: 'USD,EUR' })
    }
  }
  const debounceFetch = useCallback(
    debounce((...args) => debouncedFunctionRef.current(...args), 500),
    []
  )

  function handleAssetConversion() {
    const newConversionData = []
    Object.keys(assetConversionData.data).forEach((asset) => {
      Object.keys(assetConversionData.data[asset]).forEach((currency) => {
        if (currency === 'USD') {
          setFormData({
            ...formData,
            transfer: {
              ...formData.transfer,
              amount_usd: assetConversionData.data[asset][currency] * formData.transfer.amount,
            },
          })
        }
        newConversionData.push(
          `${numberWithCommas(
            Math.round(assetConversionData.data[asset][currency] * formData.transfer.amount * 100) / 100
          )} ${currency}`
        )
      })
    })
    setCurrencyConversion(newConversionData)
    setIsAmountUsdLoading(false)
  }

  const handleTransactionChange = (value) => {
    setIsAmountUsdLoading(true)
    setFormData({ ...formData, transfer: { ...formData.transfer, ...value } })
  }
  const handleAssetChange = (value) => {
    setIsAmountUsdLoading(true)
    setFormData({ ...formData, transfer: { ...formData.transfer, ...value } })
  }

  // useEffect
  useEffect(() => assetConversionData && handleAssetConversion(), [assetConversionData])
  useEffect(() => {
    if (!formData.transfer.amount_usd) {
      setIsAmountUsdLoading(true)
    } else if (formData.transfer.amount_usd) {
      setCurrencyConversion([`${numberWithCommas(Math.round(formData.transfer.amount_usd * 100) / 100)} USD`])
      setIsAmountUsdLoading(false)
    }
  }, [])
  useEffect(() => {
    if (isAmountUsdLoading) setFormData({ ...formData, transfer: { ...formData.transfer, amount_usd: null } })
  }, [isAmountUsdLoading])

  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormLabel>Please enter transfer amount</FormLabel>
          <FormInputGroupItem>
            <TextField
              placeholder="Amount e.g. 1.5"
              type="number"
              min="0"
              step="0.1"
              value={formData?.transfer?.amount}
              onChange={(e) => {
                handleTransactionChange({ amount: e.target.value })
                debounceFetch()
              }}
            />
            <SimpleSelect
              placeholder="Asset"
              options={CHAIN_TYPE_SIMPLIFIED_OPTIONS}
              value={formData?.transfer?.asset_code}
              onChange={(value) => {
                handleAssetChange({ asset_code: value })
                debounceFetch()
              }}
            />
            <SubText>
              {isAssetConversionLoading ? 'Loading...' : currencyConversion.join(' | ')}
              {!isAssetConversionLoading && !assetConversionData && !formData.transfer.amount_usd && ' No Data'}
            </SubText>
          </FormInputGroupItem>
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
AmountTransferForm.defaultProps = {
  formData: {},
  setFormData: () => {},
  actions: {},
  form: {},
}

// Proptypes Validation
AmountTransferForm.propTypes = {
  formData: PropTypes.shape({
    transfer: PropTypes.shape({
      amount: PropTypes.number,
      amount_usd: PropTypes.number,
      asset_code: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsVaspDrawerOpen: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdVasp: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AmountTransferForm)
