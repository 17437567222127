/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  SearchWrapper,
  IconWrapper,
  FilterWrapper,
  FilterContainer,
  BottomOptionsWrapper,
  BottomOption,
  Label,
  SearchInput,
  SelectInputOptionsContainer,
  SelectInputOptions,
  SelectInputText,
  SelectInputSubText,
} from './Search.elements'

// Assets
import SearchIcon from 'assets/images/search'
import FilterIcon from 'assets/images/filter'
import CloseIcon from 'assets/images/close'

// Components
import { Button } from 'views/components'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, handleClose) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function Search(props) {
  // Destructure
  const {
    label,
    options,
    disabledOptions,
    setOptions,
    placeholder,
    filter,
    bottomOptions,
    onInputChange,
    disabled,
    onSelect,
    isLoading,
    value,
    ...rest
  } = props

  // Variables
  const withOptions = options && options?.length > 0
  const wrapperRef = useRef()

  // States
  const [inputValue, setInputValue] = useState(value || '')
  const [showOptions, setShowOptions] = useState(false)
  const [collapseFilter, setCollapseFilter] = useState(true)

  // Functions
  const handleOnSelect = ({ value, label }) => {
    setInputValue(label || value)
    onSelect(value)
    setShowOptions(false)
  }
  const handleOnChange = (e) => {
    setInputValue(e.target.value)
    onInputChange(e.target.value)
  }
  const handleCollapseFilter = () => {
    setCollapseFilter((prevState) => !prevState)
    setShowOptions(false)
  }
  const handleCloseOptions = () => {
    setShowOptions(false)
  }
  // Closing options on click away
  useOutsideAlerter(wrapperRef, handleCloseOptions)

  return (
    <SearchWrapper ref={wrapperRef} label={label} open={showOptions}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
      {filter && (
        <FilterWrapper>
          <FilterContainer collapseFilter={collapseFilter}>{filter}</FilterContainer>
          <Button
            color="default"
            size="small"
            onClick={handleCollapseFilter}
            startIcon={collapseFilter ? <FilterIcon /> : <CloseIcon />}
          >
            {collapseFilter ? 'Filters' : 'Close'}
          </Button>
        </FilterWrapper>
      )}
      <SearchInput
        value={inputValue}
        onChange={handleOnChange}
        placeholder={placeholder}
        onFocus={() => {
          if (!disabledOptions) setShowOptions(true)
          setCollapseFilter(true)
        }}
        type="search"
        autocomplete="off"
        disabled={disabled}
        {...rest}
      />
      {showOptions && (
        <SelectInputOptionsContainer>
          {withOptions ? (
            options.map(({ value, label, subLabel }) => (
              <SelectInputOptions
                onClick={() => {
                  handleOnSelect({ value, label })
                }}
                key={label}
                value={value}
              >
                <SelectInputText>{label}</SelectInputText>
                {subLabel && <SelectInputSubText>{subLabel}</SelectInputSubText>}
              </SelectInputOptions>
            ))
          ) : (
            <div>
              {isLoading ? (
                <BottomOption>loading...</BottomOption>
              ) : (
                <>
                  <BottomOption>No results found</BottomOption>
                  {bottomOptions && <BottomOptionsWrapper>{bottomOptions}</BottomOptionsWrapper>}
                </>
              )}
            </div>
          )}
        </SelectInputOptionsContainer>
      )}
    </SearchWrapper>
  )
}

// Default Props
Search.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  setOptions: () => {},
  onSelect: () => {},
  onInputChange: () => {},
  isLoading: false,
  disabled: false,
  value: '',
}

// Proptypes Validation
Search.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  setOptions: PropTypes.func,
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledOptions: PropTypes.bool,
  value: PropTypes.string,
  filter: PropTypes.any,
  bottomOptions: PropTypes.any,
}

export default Search
