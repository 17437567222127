import styled from 'styled-components'

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: ${({ label }) => label && '-5px;'};
`

export const Label = styled.label`
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 12px;
  padding: 0 8px;
  transform: translate(15px, 15px);

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`

export const TextFieldInput = styled.input`
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 10px;
  width: 100%;
  font-weight: 400;
  outline: none;
  color: ${({ theme }) => theme.colors.darkGray};
  -webkit-font-smoothing: antialiased;

  background-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.colors.lightGray)};
  border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`
