/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Assets
import DownloadIcon from 'assets/images/download'
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchRecipient, useUpdateRecipientEntity, useUpdateRecipientIndividual } from 'core/hooks/api'

// Styled Elements
import {
  Wrapper,
  Container,
  Title,
  Subtitle,
  ActivityWrapper,
  Row,
  RowHeader,
  Text,
  EditBtnWrapper,
  FooterBtnWrapper,
} from './RecipientDetails.elements'

// Views
import { Button } from 'views/components'

import {
  DirectoryDetailsSubHeader,
  RecipientIndividualDetailsForm,
  RecipientEntityDetailsForm,
  RiskDetailsForm,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function RecipientDetails(props) {
  // Destructure
  const { actions } = props
  const { recipient_id } = useParams()

  // store actions
  const { setShowHeaderLoader, showAlert } = actions

  // refs
  const formRef = useRef()

  // hooks
  const { getRecipient, recipientData, isRecipientLoading } = useFetchRecipient()
  const {
    recipientEntityUpdateData,
    updateRecipientEntity,
    isRecipientEntityUpdateLoading,
    isRecipientEntityUpdateSuccess,
  } = useUpdateRecipientEntity()
  const {
    recipientIndividualUpdateData,
    updateRecipientIndividual,
    isRecipientIndividualUpdateLoading,
    isRecipientIndividualUpdateSuccess,
  } = useUpdateRecipientIndividual()

  // state
  const [recipientDataState, setRecipientDataState] = useState()
  const [viewMode, setViewMode] = useState(true)
  const [fakeLoading, setFakeLoading] = useState(false)
  const [page, setPage] = useState(0)

  // functions
  const fetchData = () => {
    getRecipient({ recipient_id })
  }
  const handleSave = () => {
    const newPayload = formRef.current.values
    if (!newPayload) return
    if (recipientDataState.recipient_type_id === 1) {
      updateRecipientIndividual({ recipient_id, values: newPayload })
    }
    if (recipientDataState.recipient_type_id === 2) {
      updateRecipientEntity({ recipient_id, values: newPayload })
    }
  }
  const handleCancel = () => {
    setViewMode(true)
    setFakeLoading(true)
    if (recipientEntityUpdateData) return setRecipientDataState(recipientEntityUpdateData)
    if (recipientIndividualUpdateData) return setRecipientDataState(recipientIndividualUpdateData)
    return setRecipientDataState(recipientData)
  }
  const handleLoadingUpdateChange = () => {
    if (isRecipientEntityUpdateLoading || isRecipientIndividualUpdateLoading) {
      setShowHeaderLoader(true)
    }
    if (!isRecipientEntityUpdateLoading && !isRecipientIndividualUpdateLoading) {
      setShowHeaderLoader(false)
      if (recipientDataState) {
        if (recipientDataState?.recipient_type_id === 1) {
          if (isRecipientIndividualUpdateSuccess) {
            setRecipientDataState(recipientIndividualUpdateData)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isRecipientIndividualUpdateSuccess) {
            handleCancel()
            showAlert({ type: 'error', message: 'An error occured in updating record' })
          }
        } else if (recipientDataState?.recipient_type_id === 2) {
          if (isRecipientEntityUpdateSuccess) {
            setRecipientDataState(recipientEntityUpdateData)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isRecipientEntityUpdateSuccess) {
            handleCancel()
            showAlert({ type: 'error', message: 'An error occured in updating record' })
          }
        }
        setViewMode(true)
      }
    }
  }
  const handleLoadingRecipient = () => {
    if (isRecipientLoading) setShowHeaderLoader(true)
    if (!isRecipientLoading) setShowHeaderLoader(false)
  }

  // useEffect
  useEffect(() => fetchData(), [])
  useEffect(() => handleCancel(), [page])
  useEffect(() => recipientData && setRecipientDataState(recipientData), [recipientData])
  useEffect(() => handleLoadingRecipient(), [isRecipientLoading])
  useEffect(() => fakeLoading && setFakeLoading(false), [fakeLoading])
  useEffect(() => {
    if (formRef?.current) handleLoadingUpdateChange()
  }, [isRecipientEntityUpdateLoading, isRecipientIndividualUpdateLoading])
  return (
    <Wrapper>
      {!isRecipientLoading && recipientDataState && (
        <>
          <Title>ID: {recipientDataState.external_id}</Title>{' '}
          <Subtitle>
            {recipientDataState.recipient_type_id === 1 ? 'INDIVIDUAL RECIPIENT' : 'BUSINESS RECIPIENT'}
          </Subtitle>
          <Container>
            <DirectoryDetailsSubHeader page={page} setPage={setPage} />
            {page === 0 && (
              <>
                {viewMode === true && (
                  <EditBtnWrapper>
                    <Button color="primary" onClick={() => setViewMode(false)}>
                      Edit
                    </Button>
                  </EditBtnWrapper>
                )}
                {recipientDataState.recipient_type_id === 1
                  ? !fakeLoading && (
                      <RecipientIndividualDetailsForm
                        values={recipientDataState}
                        viewMode={viewMode}
                        formRef={formRef}
                      />
                    )
                  : !fakeLoading && (
                      <RecipientEntityDetailsForm values={recipientDataState} viewMode={viewMode} formRef={formRef} />
                    )}
                {viewMode === false && (
                  <FooterBtnWrapper>
                    <Button type="button" onClick={handleCancel} variant="outlined" startIcon={<CloseIcon />}>
                      Cancel
                    </Button>
                    <Button type="button" onClick={handleSave} startIcon={<DownloadIcon />}>
                      Save Changes
                    </Button>
                  </FooterBtnWrapper>
                )}
              </>
            )}

            {page === 1 && (
              <ActivityWrapper>
                <Row>
                  <RowHeader>Account Activity</RowHeader> <RowHeader>Value</RowHeader>
                </Row>
                <Row>
                  <Text>Pending Transfers</Text> <Text>{recipientDataState.open_transfers}</Text>
                </Row>
                <Row>
                  <Text>Open Alerts</Text> <Text>{recipientDataState.open_alerts}</Text>
                </Row>
              </ActivityWrapper>
            )}

            {page === 2 && (
              <>
                {viewMode === true && (
                  <EditBtnWrapper>
                    <Button color="primary" onClick={() => setViewMode(false)}>
                      Edit
                    </Button>
                  </EditBtnWrapper>
                )}
                {!fakeLoading && <RiskDetailsForm values={recipientDataState} viewMode={viewMode} formRef={formRef} />}
                {viewMode === false && (
                  <FooterBtnWrapper>
                    <Button type="button" onClick={handleCancel} variant="outlined" startIcon={<CloseIcon />}>
                      Cancel
                    </Button>
                    <Button type="button" onClick={handleSave} startIcon={<DownloadIcon />}>
                      Save Changes
                    </Button>
                  </FooterBtnWrapper>
                )}
              </>
            )}
          </Container>
        </>
      )}
    </Wrapper>
  )
}

// Default Props
RecipientDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
RecipientDetails.propTypes = {
  filters: PropTypes.shape({
    recipientDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setRecipientDirectoryFilters: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientDetails)
