import { authAxios } from 'common/utils/axios'

import { ACCOUNT_ENDPOINTS } from 'common/constants/apiUrls'

import axios from 'axios'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const authAxiosSecondServer = axios.create({
  baseURL: 'https://dev-detect.ospree.io/',
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const GetAllAccounts = async (values) => {
  const response = await authAxios.get(ACCOUNT_ENDPOINTS.GET_ALL_ACCOUNTS, {
    params: { ...values },
  })

  const { data } = response

  return data
}

export const GetAccount = async ({ account_id }) => {
  const response = await authAxios.get(`${ACCOUNT_ENDPOINTS.GET_ACCOUNT}${account_id}`)

  const { data } = response

  return data
}
export const SearchAccount = async ({ search_term }) => {
  const response = await authAxios.get(ACCOUNT_ENDPOINTS.SEARCH_ACCOUNT, {
    params: {
      search_term,
    },
  })

  const { data } = response

  return data
}

export const CreateAccountEntity = async (values) => {
  const response = await authAxios.post(ACCOUNT_ENDPOINTS.CREATE_ACCOUNT_ENTITY, values)

  const { data } = response

  return data
}
export const CreateAccountIndividual = async (values) => {
  const response = await authAxios.post(ACCOUNT_ENDPOINTS.CREATE_ACCOUNT_INDIVIDUAL, values)

  const { data } = response

  return data
}
export const UpdateAccountEntity = async ({ account_id, values }) => {
  const response = await authAxios.patch(`${ACCOUNT_ENDPOINTS.UPDATE_ACCOUNT_ENTITY}${account_id}`, {
    ...values,
  })

  const { data } = response

  return data
}
export const UpdateAccountIndividual = async ({ account_id, values }) => {
  const response = await authAxios.patch(`${ACCOUNT_ENDPOINTS.UPDATE_ACCOUNT_INDIVIDUAL}${account_id}`, {
    ...values,
  })

  const { data } = response

  return data
}
