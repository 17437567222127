import { authAxios } from 'common/utils/axios'
import { REPORT_ENDPOINTS } from '../../common/constants/apiUrls'

import { LOCAL_URL, BASE_URL } from 'common/constants/apiUrls'
import axios from 'axios'

const { GET_REPORTS, GET_REPORT } = REPORT_ENDPOINTS

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const newAuthAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const GetReports = async ({ start_range, end_range, sort, page, size }) => {
  console.log({ start_range, end_range, sort, page, size })
  const response = await newAuthAxios.get(GET_REPORTS, {
    params: {
      start_range,
      end_range,
      sort,
      page,
      size,
    },
  })

  const { data } = response.data

  return data
}
export const GetReport = async ({ format, report_id }) => {
  const response = await newAuthAxios.get(`${GET_REPORT}/${report_id}`, {
    params: { format },
  })

  return response.data
}
