/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/close'
import ArrowLeftIcon from 'assets/images/arrowLeft'

// Constants
import { TRAVELRULE_NAVIGATION_HEADERS } from 'common/constants/travelRule'

// Hooks
import { useCreateTransfer } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  CreateEntityDrawerWrapper,
  BackButtonWrapper,
  DrawerNavWrapper,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerCloseIcon,
  DrawerTitle,
} from './TransferDrawer.elements'

// Views
import { Drawer, Button, FormNavigation } from 'views/components'

import {
  AmountTransferForm,
  VaspTransferForm,
  AccountTransferForm,
  RecipientTransferForm,
  SummaryTransferForm,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function TransferDrawer(props) {
  // Destructure
  const { ui, actions, form } = props

  // Store State
  const { isTransferDrawerOpen } = ui
  const { createdAccount, createdRecipient, createdVasp } = form

  // Store Actions
  const {
    setCreatedAccount,
    setCreatedRecipient,
    setCreatedVasp,
    setIsTransferDrawerOpen,
    setIsPageTableUpdated,
    showAlert,
  } = actions

  // Hooks
  const { createTransfer, isTransferCreateSuccess, isTransferCreateLoading } = useCreateTransfer()

  // State
  const [credentials] = useLocalStorage('userCredentials', '')
  const [disabledContinue, setDisabledContinue] = useState(true)
  const [formPage, setFormPage] = useState(1)
  const [recipientAddress, setRecipientAddress] = useState()
  const [recipientVaspOptions, setRecipientVaspOptions] = useState()
  const [formData, setFormData] = useState({
    transfer: {},
    account: null,
    recipient: null,
    vasp: null,
  })

  // Functions
  const handleBack = () => {
    if (formPage <= 1) return
    setFormPage((prevState) => prevState - 1)
  }
  const handleContinue = () => {
    if (formPage >= 5) {
      return createTransfer({
        amount: Number(formData.transfer.amount),
        amount_usd: formData.transfer.amount_usd,
        asset_code: formData.transfer.asset_code,
        originator_hash_address: formData.transfer.originator_hash_address,
        recipient_hash_address: formData.transfer.recipient_hash_address,
        account_id: formData.account.account_id,
        // TODO ORIG VASP ID IS TEMPORARY, FIX IT
        originator_vasp_id: credentials.business_id,
        recipient_vasp_id: formData.vasp.vasp_id,
        recipient_id: formData.recipient.recipient_id,
      })
    }
    setFormPage((prevState) => prevState + 1)
  }
  const handleCloseDrawer = () => {
    setFormData({
      transfer: {},
      account: null,
      recipient: null,
      vasp: null,
    })
    setFormPage(1)
    setIsTransferDrawerOpen(false)
  }

  // UseEffects
  useEffect(() => {
    if (createdAccount) {
      setFormData({
        ...formData,
        account: createdAccount,
        transfer: { ...formData.transfer, account_id: createdAccount.account_id },
      })
      setCreatedAccount(null)
    }
    if (createdRecipient) {
      setFormData({
        ...formData,
        recipient: createdRecipient,
        transfer: { ...formData.transfer, recipient_id: createdRecipient.recipient_id },
      })
      setCreatedRecipient(null)
    }
    if (createdVasp) {
      setFormData({ ...formData, vasp: createdVasp })
      setCreatedVasp(null)
    }
  }, [createdAccount, createdRecipient, createdVasp])

  useEffect(() => {
    if (formPage !== 5) setDisabledContinue(true)
    if (formPage === 1) {
      if (!formData.transfer?.amount || !formData.transfer?.asset_code || !formData.transfer?.amount_usd) {
        setDisabledContinue(true)
      } else setDisabledContinue(false)
    }
    if (formPage === 2) {
      if (!formData.account || !formData.transfer?.originator_hash_address) setDisabledContinue(true)
      else setDisabledContinue(false)
    }
    if (formPage === 3) {
      if (!formData.recipient || !formData.transfer?.recipient_hash_address) setDisabledContinue(true)
      else setDisabledContinue(false)
    }
    if (formPage === 4) {
      if (!formData.vasp) setDisabledContinue(true)
      else setDisabledContinue(false)
    }
  }, [formData, formPage])

  useEffect(() => {
    if (formPage < 5) return
    if (isTransferCreateSuccess) {
      setIsPageTableUpdated(true)
      showAlert({ type: 'success', message: 'Successfully created transfer' })
      setIsTransferDrawerOpen(false)
      setFormPage(1)
      setFormData({
        transfer: {},
        account: null,
        recipient: null,
        vasp: null,
      })
    } else if (!isTransferCreateSuccess) {
      showAlert({ type: 'error', message: 'An error occured in creating transfer' })
    }
  }, [isTransferCreateSuccess])

  return (
    <Drawer open={isTransferDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <CreateEntityDrawerWrapper>
        <DrawerNavWrapper>
          <BackButtonWrapper>
            {formPage > 1 && (
              <Button startIcon={<ArrowLeftIcon />} variant="outlined" onClick={handleBack}>
                Back
              </Button>
            )}
          </BackButtonWrapper>
          <DrawerCloseIcon onClick={handleCloseDrawer}>
            <CloseIcon />
          </DrawerCloseIcon>
        </DrawerNavWrapper>

        <DrawerHeader>
          <DrawerTitle>
            {formPage === 1 && 'Amount'}
            {formPage === 2 && 'Sender'}
            {formPage === 3 && 'Recipient'}
            {formPage === 4 && 'Counterparty VASP'}
            {formPage === 5 && 'Confirm'}
          </DrawerTitle>
          <FormNavigation pageNumber={formPage} headers={TRAVELRULE_NAVIGATION_HEADERS} />
        </DrawerHeader>

        <DrawerContent>
          {formPage === 1 && <AmountTransferForm formData={formData} setFormData={setFormData} />}
          {formPage === 2 && <AccountTransferForm formData={formData} setFormData={setFormData} />}
          {formPage === 3 && <RecipientTransferForm formData={formData} setFormData={setFormData} />}
          {formPage === 4 && (
            <VaspTransferForm
              formData={formData}
              setFormData={setFormData}
              recipientAddress={recipientAddress}
              setRecipientAddress={setRecipientAddress}
              recipientVaspOptions={recipientVaspOptions}
              setRecipientVaspOptions={setRecipientVaspOptions}
            />
          )}
          {formPage === 5 && (
            <>
              {isTransferCreateLoading ? (
                <DrawerTitle>{formPage === 5 && isTransferCreateLoading && 'Loading...'}</DrawerTitle>
              ) : (
                <SummaryTransferForm formData={formData} setFormData={setFormData} setFormPage={setFormPage} />
              )}
            </>
          )}
        </DrawerContent>
        <DrawerFooter formPage={formPage}>
          <Button disabled={disabledContinue} onClick={handleContinue}>
            {' '}
            {formPage === 5 ? 'Confirm' : 'Continue'}
          </Button>
        </DrawerFooter>
      </CreateEntityDrawerWrapper>
    </Drawer>
  )
}

// Default Props
TransferDrawer.defaultProps = {
  ui: {},
  actions: {},
  form: {},
}

// Proptypes Validation
TransferDrawer.propTypes = {
  ui: PropTypes.shape({ isTransferDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    setIsTransferDrawerOpen: PropTypes.func,
    setCreatedAccount: PropTypes.func,
    setCreatedRecipient: PropTypes.func,
    setCreatedVasp: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdAccount: PropTypes.shape({ account_id: PropTypes.string }),
    createdRecipient: PropTypes.shape({ recipient_id: PropTypes.string }),
    createdVasp: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferDrawer)
