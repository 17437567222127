/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
// import { useFetchTransfer } from 'core/hooks/api'

// Assets
import { CopyIcon } from 'assets/images'

// Utils
import { numberWithCommas, compactNumberFormatter } from 'common/utils/valueFormat'

// Constants
import { BLOCKCHAIN_MONO_SVGS } from 'common/constants/blockchainMonoSvgs'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableColWrapper,
  TableRowContainer,
  TableText,
  // TableTextTiny,
  TableSubText,
  TablePanelWrapper,
  AddressHash,
  AddressHashWrapper,
  CopyNotification,
} from './AddressesTableItem.elements'

// Views
import { AccordionTableItem, RiskBar, TextSliced } from 'views/components'
import { AddressesTableItemDetails } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function AddressesTableItem(props) {
  // Destructure
  const { data, address, actions, getAddressesError, openedTab, setOpenedTab } = props

  const { addressLinkData, addressLinkDetails, scannedAddressDetails } = address
  const {
    setAddressLinkData,
    setAddressLinkDetails,
    setScannedAddressDetails,
    setAddressFilters,
    setIsPageTableUpdated,
  } = actions

  // States
  const [barWidth, setBarWidth] = useState('0%')
  const [isExpanded, setIsExpanded] = useState(false)
  const [chainIcon, setChainIcon] = useState()

  // Component States
  const [addressData, setAddressData] = useState({
    id: data.id,
    address: data.address,
    chain: data.chain,
    symbol: data.symbol,
    risk_score: data.risk_score,
    risk_ratio: data.risk_ratio,
    balance_usd: data.balance_usd,
    balance: data.balance,
    modify_tz: data.modify_tz,
    link_id: data.link_id,
    link_type: data.link_type,
  })
  const { chain, symbol, balance, modify_tz, risk_ratio } = addressData
  const [financialData, setFinancialData] = useState()
  const [riskData, setRiskData] = useState()
  const [accountData, setAccountData] = useState()
  const [businessNotes, setBusinessNotes] = useState()
  const [copyNotification, setCopyNotification] = useState('')

  function handleScannedAddress() {
    setRiskData((prevValue) => {
      const newRiskData = [scannedAddressDetails.risk_data]
      if (prevValue) {
        prevValue.forEach((item) => {
          if (item.provider_name !== scannedAddressDetails.risk_data.provider_name) {
            newRiskData.push(item)
          }
        })
      }
      return newRiskData
    })
    setFinancialData(scannedAddressDetails.financial_data)
    setOpenedTab(scannedAddressDetails.address)
    setScannedAddressDetails(null)
  }

  function formatValue(number) {
    if (number < 1_000_000) {
      return numberWithCommas(Math.round(number * 100) / 100)
    }
    return compactNumberFormatter.format(number)
  }
  const copyHash = () => {
    navigator.clipboard.writeText(addressData.address).then(
      () => setCopyNotification('Copied!'),
      () => setCopyNotification('Error: Could not copy')
    )
    setTimeout(() => setCopyNotification(''), 700)
  }

  useEffect(() => {
    if (chain) setChainIcon(BLOCKCHAIN_MONO_SVGS[chain])
    if (risk_ratio) setBarWidth(`${risk_ratio}%`)
  }, [])
  useEffect(() => {
    if (addressLinkData) {
      if (addressLinkDetails.address_id === addressData.id) {
        setAccountData(addressLinkData)
        setAddressLinkData()
        setAddressLinkDetails()
      }
    }
  }, [addressLinkData])
  useEffect(
    () => scannedAddressDetails && scannedAddressDetails?.address === data.address && handleScannedAddress(),
    [scannedAddressDetails]
  )
  useEffect(() => {
    if (!isExpanded && !addressData.id && !scannedAddressDetails && !getAddressesError) {
      setAddressFilters()
      setIsPageTableUpdated(true)
      setOpenedTab()
    }
  }, [isExpanded])
  // !This doesnt work when the item was opened before
  useEffect(() => {
    if (openedTab !== data.address) setIsExpanded(false)
    if (openedTab === data.address) setIsExpanded(true)
  }, [openedTab])
  useEffect(() => setAddressData(data), [data])
  return (
    <AccordionTableItem
      setIsExpanded={(val) => {
        setIsExpanded(val)
        setOpenedTab(addressData.address)
      }}
      expandPanel={isExpanded}
      button={
        <TableButtonWrapper>
          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer>
              {chainIcon && cloneElement(chainIcon)}
              {isExpanded ? (
                <AddressHashWrapper className="hashWrapper" onClick={copyHash}>
                  <AddressHash className="hashWrapper">{addressData.address}</AddressHash>
                  <CopyIcon className="hashWrapper" />
                  <CopyNotification display={copyNotification}>{copyNotification}</CopyNotification>
                </AddressHashWrapper>
              ) : (
                <TextSliced text={addressData.address} sliceLength={7} />
              )}
            </TableRowContainer>

            <TableRowContainer>
              <TableText>{modify_tz.split('T')[0]}</TableText>
            </TableRowContainer>
          </TableRowWrapper>

          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer>
              <TableText>{risk_ratio}%</TableText>
              <RiskBar width={barWidth} />
            </TableRowContainer>

            <TableRowContainer>
              <TableColWrapper>
                <TableText>
                  {!!symbol && symbol.toUpperCase()}: {formatValue(balance)}
                </TableText>
                <TableSubText>{chain && chain}</TableSubText>
              </TableColWrapper>
            </TableRowContainer>
          </TableRowWrapper>
        </TableButtonWrapper>
      }
      panel={
        <TablePanelWrapper>
          <AddressesTableItemDetails
            isExpanded={isExpanded}
            // Details state
            financialData={financialData}
            setFinancialData={setFinancialData}
            riskData={riskData}
            setRiskData={setRiskData}
            addressData={addressData}
            setAddressData={setAddressData}
            accountDataProp={accountData}
            setAccountData={setAccountData}
            businessNotes={businessNotes}
            setBusinessNotes={setBusinessNotes}
            // Linkage state
            // Notes state
          />
          {/* <TablePanelWrapper>
          {details && isExpanded && (
            <AddressesTableItemDetails
              isLoading={isTransferLoading}
              transfer_status={details.transfer_status}
              transfer_details={details.transfer_details}
              table_data={data}
            />
          )}
        </TablePanelWrapper> */}
        </TablePanelWrapper>
      }
    />
  )
}

// Default Props
AddressesTableItem.defaultProps = {
  address: {},
  actions: {},
  data: {
    id: '',
    address: '',
    chain: '',
    symbol: '',
    risk_score: 0,
    balance_usd: 0,
    balance: 0,
    modify_tz: '',
    link_type: 1,
    link_id: '',
  },
}

// Proptypes Validation
AddressesTableItem.propTypes = {
  getAddressesError: PropTypes.bool,
  openedTab: PropTypes.string,
  setOpenedTab: PropTypes.func,
  address: PropTypes.shape({
    addressLinkDetails: PropTypes.shape({
      address_id: PropTypes.string,
    }),
    addressLinkData: PropTypes.shape({}),
    scannedAddressDetails: PropTypes.shape({
      id: PropTypes.string,
      address: PropTypes.string,
      chain: PropTypes.string,
      symbol: PropTypes.string,
      symbol_icon: PropTypes.string,
      financial_data: {
        provider_name: PropTypes.string,
        balance: PropTypes.number,
        balance_usd: PropTypes.number,
        spent: PropTypes.number,
        spent_usd: PropTypes.number,
        received: PropTypes.number,
        received_usd: PropTypes.number,
        transaction_count: PropTypes.number,
        latest_activity: PropTypes.string,
      },
      risk_data: {
        provider_name: PropTypes.string,
        risk_score: PropTypes.number,
        vasp_name_legal: PropTypes.string,
        vasp_name_business: PropTypes.string,
        vasp_website: PropTypes.string,
        vasp_category: PropTypes.string,
        api_result_id: PropTypes.string,
      },
      request_tz: PropTypes.string,
      link_id: PropTypes.string,
      link_type: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setAddressLinkData: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
    setScannedAddressDetails: PropTypes.func,
    setAddressFilters: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
    modified_by_name: PropTypes.string,
  }),
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressesTableItem)
