import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TransferFormWrapper,
  FormGroupWrapper,
  FormRowContainer,
  FormGroupTitle,
  FormGroupText,
  FormWrapper,
  FormButtonWrapper,
} from './SummaryTransferForm.elements'

// Views
import { Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function SummaryTransferForm(props) {
  // Destructure
  const { formData, setFormData, actions, setFormPage, form } = props
  const { createdAccount, createdRecipient, createdVasp } = form

  // Store Actions
  const {
    setToBeUpdatedAccount,
    setToBeUpdatedRecipient,
    setToBeUpdatedVasp,

    setCreatedAccount,
    setCreatedRecipient,
    setCreatedVasp,

    setIsIndividualAccountDrawerOpen,
    setIsEntityAccountDrawerOpen,
    setIsIndividualRecipientDrawerOpen,
    setIsEntityRecipientDrawerOpen,
    setIsVaspDrawerOpen,
  } = actions

  // Internal State
  const [formState, setFormState] = useState(formData)

  // functions
  const handleEditVasp = () => {
    setToBeUpdatedVasp(formData.vasp)
    setIsVaspDrawerOpen(true)
  }
  const handleEditAccount = () => {
    setToBeUpdatedAccount(formData.account)
    if (formState.account.account_type_id === 1) {
      setIsIndividualAccountDrawerOpen(true)
    } else if (formState.account.account_type_id === 2) {
      setIsEntityAccountDrawerOpen(true)
    }
  }
  const handleEditRecipient = () => {
    setToBeUpdatedRecipient(formState.recipient)
    if (formState.recipient.recipient_type_id === 1) {
      setIsIndividualRecipientDrawerOpen(true)
    } else if (formState.recipient.recipient_type_id === 2) {
      setIsEntityRecipientDrawerOpen(true)
    }
  }

  // useEffect
  useEffect(() => setFormState(formData), [formData])
  useEffect(() => {
    if (createdAccount) {
      setFormData({ ...formData, account: createdAccount })
      setCreatedAccount(null)
    }
    if (createdRecipient) {
      setFormData({ ...formData, account: createdRecipient })
      setCreatedRecipient(null)
    }
    if (createdVasp) {
      setFormData({ ...formData, account: createdVasp })
      setCreatedVasp(null)
    }
  }, [createdAccount, createdRecipient, createdVasp])
  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormRowContainer>
            <FormGroupTitle>Amount</FormGroupTitle>
            <FormButtonWrapper>
              <Button size="tiny" variant="outlined" onClick={() => setFormPage(1)}>
                Change
              </Button>
            </FormButtonWrapper>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Amount: </FormGroupText>
            <FormGroupText>{formState.transfer?.amount}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Asset: </FormGroupText>
            <FormGroupText>{formState.transfer?.asset_code}</FormGroupText>
          </FormRowContainer>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FormRowContainer>
            <FormGroupTitle>Sender</FormGroupTitle>
            <FormButtonWrapper>
              <Button size="tiny" variant="outlined" onClick={handleEditAccount} disabled={!formState.account}>
                Edit
              </Button>
              <Button size="tiny" variant="outlined" onClick={() => setFormPage(2)}>
                Change
              </Button>
            </FormButtonWrapper>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Sender Address Hash: </FormGroupText>
            <FormGroupText>{formState.transfer?.originator_hash_address}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>
              {formState.account?.account_type_id === 1 ? 'First Name: ' : 'Business Name: '}
            </FormGroupText>
            <FormGroupText>
              {formState.account?.account_type_id === 1
                ? formState.account?.name_first
                : formState.account?.name_business}
            </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>{formState.account?.account_type_id === 1 ? 'Last Name: ' : 'Legal Name: '}</FormGroupText>
            <FormGroupText>
              {formState.account?.account_type_id === 1 ? formState.account?.name_last : formState.account?.name_legal}
            </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Street: </FormGroupText>
            <FormGroupText> {formState.account?.address_street} </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>City: </FormGroupText>
            <FormGroupText> {formState.account?.address_city} </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText> Zipcode: </FormGroupText>
            <FormGroupText> {formState.account?.address_postcode} </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText> Country: </FormGroupText>
            <FormGroupText> {formState.account?.address_country_code} </FormGroupText>
          </FormRowContainer>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FormRowContainer>
            <FormGroupTitle>Recipient</FormGroupTitle>
            <FormButtonWrapper>
              <Button size="tiny" variant="outlined" onClick={handleEditRecipient} disabled={!formState.recipient}>
                Edit
              </Button>
              <Button size="tiny" variant="outlined" onClick={() => setFormPage(3)}>
                Change
              </Button>
            </FormButtonWrapper>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Recipient Address Hash: </FormGroupText>
            <FormGroupText>{formState.transfer?.recipient_hash_address}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>
              {formState.recipient?.recipient_type_id === 1 ? 'First Name: ' : 'Business Name: '}
            </FormGroupText>
            <FormGroupText>
              {formState.recipient?.recipient_type_id === 1
                ? formState.recipient?.name_first
                : formState.recipient?.name_business}
            </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>
              {formState.recipient?.recipient_type_id === 1 ? 'Last Name: ' : 'Legal Name: '}
            </FormGroupText>
            <FormGroupText>
              {formState.recipient?.recipient_type_id === 1
                ? formState.recipient?.name_last
                : formState.recipient?.name_legal}
            </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Street: </FormGroupText>
            <FormGroupText> {formState.recipient?.address_street} </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>City: </FormGroupText>
            <FormGroupText> {formState.recipient?.address_city} </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText> Zipcode: </FormGroupText>
            <FormGroupText> {formState.recipient?.address_postcode} </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText> Country: </FormGroupText>
            <FormGroupText> {formState.recipient?.address_country_code} </FormGroupText>
          </FormRowContainer>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FormRowContainer>
            <FormGroupTitle>Counterparty VASP</FormGroupTitle>
            <FormButtonWrapper>
              <Button size="tiny" variant="outlined" onClick={handleEditVasp} disabled={!formState.vasp}>
                Edit
              </Button>
              <Button size="tiny" variant="outlined" onClick={() => setFormPage(4)}>
                Change
              </Button>
            </FormButtonWrapper>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Beneficiary VASP Name: </FormGroupText>
            <FormGroupText>{formState.vasp?.name_business}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Website: </FormGroupText>
            <FormGroupText>{formState.vasp?.website}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Legal Name: </FormGroupText>
            <FormGroupText>{formState.vasp?.name_legal}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Country: </FormGroupText>
            <FormGroupText>{formState.vasp?.address_country_code}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Contact Email: </FormGroupText>
            <FormGroupText>{formState.vasp?.email}</FormGroupText>
          </FormRowContainer>
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
SummaryTransferForm.defaultProps = {
  formData: {},
  actions: {},
  form: {},
}

// Proptypes Validation
SummaryTransferForm.propTypes = {
  formData: PropTypes.shape({
    account: PropTypes.shape({}),
    recipient: PropTypes.shape({}),
    vasp: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,

    setCreatedAccount: PropTypes.func,
    setCreatedRecipient: PropTypes.func,
    setCreatedVasp: PropTypes.func,

    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdAccount: PropTypes.shape({}),
    createdRecipient: PropTypes.shape({}),
    createdVasp: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
  setFormPage: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTransferForm)
