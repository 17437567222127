import styled from 'styled-components'

export const TableFilterFormWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & #travelRuleSort {
    min-width: 245px;
    & .simpleSelectInputLabel {
      min-width: 165px;
    }
  }
  & #addressDateFilter {
    & > div {
      max-width: none !important;
      padding: 0;
      height: 100%;
      gap: 10px;

      & p {
        font-size: 12px !important;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
`
export const FilterWrapper = styled.div``
