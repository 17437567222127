import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// Styled Elements
import { Text, Label, Title, SubTitle, TableColWrapper, TableRowContainer } from './AccountsTableItem.elements'

// Constants
import { RISK_OPTIONS } from 'common/constants/formOptions'

// Views
import { CardTableItem, Button } from 'views/components'

function AccountsTableItem(props) {
  // Destructure
  const { accountDetails } = props
  const {
    external_id,
    account_id,
    // account_risk_desc,
    account_risk_value,
    account_type_id,
    // address_country_code,
    address_country_desc,
    // created,
    display_country,
    display_date,
    display_name,
    open_alerts,
    open_transfers,
    status_desc,
    // status_id,
  } = accountDetails

  // Functions

  // useEffect

  return (
    <NavLink to={`/directory/accounts/details/${account_id}`}>
      <CardTableItem key={external_id}>
        <TableColWrapper pending={open_transfers > 0}>
          <Title>ID: {external_id}</Title>
          <SubTitle>{account_type_id === 1 ? 'INDIVIDUAL ACCOUNT' : 'BUSINESS ACCOUNT'}</SubTitle>
          <Button variant="outlined" disabled={open_transfers < 1} size="tiny">
            PENDING TRANSFERS
          </Button>
        </TableColWrapper>

        <TableColWrapper>
          <TableRowContainer>
            <Label>{account_type_id === 1 ? 'Name: ' : 'Company Name: '}</Label>
            <Text>{display_name || 'Not Available'}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>Country of Domicile: </Label>
            <Text>{address_country_desc || 'Not Available'}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>{account_type_id === 1 ? 'Date of Birth: ' : 'Date of Incorporation: '} </Label>
            <Text>{display_date}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>{account_type_id === 1 ? 'Nationality: ' : 'Country of Incorporation: '} </Label>
            <Text>{display_country || 'Not Available'}</Text>
          </TableRowContainer>
        </TableColWrapper>

        <TableColWrapper>
          <TableRowContainer>
            <Label>Status: </Label>
            <Text>{status_desc}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>Account Risk: </Label>
            <Text>{account_risk_value && RISK_OPTIONS[account_risk_value].label}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>Open Alerts: </Label>
            <Text>{open_alerts}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>Transfers: </Label>
            <Text>{open_transfers}</Text>
          </TableRowContainer>
        </TableColWrapper>
      </CardTableItem>
    </NavLink>
  )
}

// Default Props
AccountsTableItem.defaultProps = {}

// Proptypes Validation
AccountsTableItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accountDetails: PropTypes.any,
}

export default AccountsTableItem
