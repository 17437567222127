import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// Styled Elements
import {
  Text,
  Label,
  Title,
  SubTitle,
  TableColWrapper,
  TableColContainer,
  TableRowWrapper,
  TableRowContainer,
} from './RecipientsTableItem.elements'

// Views
import { CardTableItem } from 'views/components'

function RecipientsTableItem(props) {
  // Destructure
  const { recipientDetails } = props
  const {
    recipient_id,
    recipient_type_id,
    name_business,
    name_first,
    name_last,
    external_id,
    email,
    linked_addresses,
  } = recipientDetails

  // State
  const [cardName, setCardName] = useState({})

  // Functions

  // useEffect
  useEffect(() => {
    if (recipient_type_id === 1) {
      setCardName({
        initials: name_first[0] + name_last[0],
        fullname: [name_first, name_last],
      })
    }
    if (recipient_type_id === 2) {
      const nameArr = name_business.split(' ')
      const firstNameLength = Math.floor(nameArr.length / 2)
      const firstName = []
      for (let i = 0; i < firstNameLength; i++) {
        firstName.push(nameArr[i])
      }
      nameArr.splice(0, firstNameLength)
      setCardName({
        initials: name_business[0] + name_business[1],
        fullname: [firstName.join(' '), nameArr.join(' ')],
      })
    }
  }, [external_id])

  return (
    <NavLink to={`/directory/recipients/details/${recipient_id}`}>
      <CardTableItem key={external_id}>
        <TableRowWrapper>
          <Title>{cardName.initials || ''}</Title>
          <TableColContainer>
            <SubTitle>{cardName.fullname && cardName.fullname[0]}</SubTitle>
            <SubTitle>{cardName.fullname && cardName.fullname[1]}</SubTitle>
          </TableColContainer>
        </TableRowWrapper>

        <TableColWrapper>
          <TableRowContainer>
            <Label>ID: </Label>
            <Text>{external_id}</Text>
          </TableRowContainer>

          <TableRowContainer>
            <Label>Email: </Label>
            <Text>{email}</Text>
          </TableRowContainer>

          <TableRowContainer>
            <Label>Linked Addresses: </Label>
            <Text>{linked_addresses}</Text>
          </TableRowContainer>
        </TableColWrapper>
      </CardTableItem>
    </NavLink>
  )
}

// Default Props
RecipientsTableItem.defaultProps = {
  recipientDetails: {},
}

// Proptypes Validation
RecipientsTableItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recipientDetails: PropTypes.shape({
    recipient_id: PropTypes.string,
    recipient_type_id: PropTypes.number,
    name_business: PropTypes.string,
    name_first: PropTypes.string,
    name_last: PropTypes.string,
    external_id: PropTypes.string,
    email: PropTypes.string,
    linked_addresses: PropTypes.number,
  }),
}

export default RecipientsTableItem
