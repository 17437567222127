import { authAxios } from 'common/utils/axios'

import { RECIPIENT_ENDPOINTS } from 'common/constants/apiUrls'

import axios from 'axios'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const authAxiosSecondServer = axios.create({
  baseURL: 'https://dev-detect.ospree.io/',
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const GetAllRecipients = async (values) => {
  const response = await authAxios.get(RECIPIENT_ENDPOINTS.GET_ALL_RECIPIENTS, {
    params: { ...values },
  })

  const { data } = response

  return data
}

export const GetRecipient = async ({ recipient_id }) => {
  const response = await authAxios.get(`${RECIPIENT_ENDPOINTS.GET_RECIPIENT}${recipient_id}`)

  const { data } = response

  return data
}
export const SearchRecipient = async ({ search_term }) => {
  const response = await authAxios.get(RECIPIENT_ENDPOINTS.SEARCH_RECIPIENT, {
    params: {
      search_term,
    },
  })

  const { data } = response

  return data
}

export const CreateRecipientEntity = async (values) => {
  const response = await authAxios.post(RECIPIENT_ENDPOINTS.CREATE_RECIPIENT_ENTITY, values)

  const { data } = response

  return data
}
export const CreateRecipientIndividual = async (values) => {
  const response = await authAxios.post(RECIPIENT_ENDPOINTS.CREATE_RECIPIENT_INDIVIDUAL, values)

  const { data } = response

  return data
}

export const UpdateRecipientEntity = async ({ recipient_id, values }) => {
  const response = await authAxios.patch(`${RECIPIENT_ENDPOINTS.UPDATE_RECIPIENT_ENTITY}${recipient_id}`, {
    ...values,
  })

  const { data } = response

  return data
}
export const UpdateRecipientIndividual = async ({ recipient_id, values }) => {
  const response = await authAxios.patch(`${RECIPIENT_ENDPOINTS.UPDATE_RECIPIENT_INDIVIDUAL}${recipient_id}`, {
    ...values,
  })

  const { data } = response

  return data
}
