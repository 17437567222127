import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// Styled Elements
import {
  SidebarWrapper,
  SidebarBrand,
  SidebarBrandLogo,
  SidebarMenuNav,
  SidebarMenuNavList,
  SidebarMenuNavItem,
  SidebarMenuNavLink,
  SidebarMenuNavText,
} from './Sidebar.elements'

// assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'
import BlockchainAnalyticsIcon from 'assets/images/blockchainAnalytics'
import CaseManagementIcon from 'assets/images/caseManagement'
import DirectoryIcon from 'assets/images/directory'
import PlusDocumentIcon from 'assets/images/plusDocument'
import TravelRuleIcon from 'assets/images/travelRule'
import SettingsIcon from 'assets/images/settings'

function Sidebar(props) {
  // Destructure
  const { children } = props

  return (
    <SidebarWrapper>
      <SidebarMenuNav>
        <SidebarMenuNavList>
          <SidebarBrand>
            <NavLink to="/">
              <SidebarBrandLogo>
                <OspreeLogoIcon />
              </SidebarBrandLogo>
            </NavLink>
          </SidebarBrand>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/blockchain-analytics">
              <BlockchainAnalyticsIcon />
              <SidebarMenuNavText>Blockchain Analytics</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink exact to="/">
              <TravelRuleIcon />
              <SidebarMenuNavText>Travel Rule</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/directory">
              <DirectoryIcon />
              <SidebarMenuNavText>Directory</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/reports">
              <PlusDocumentIcon />
              <SidebarMenuNavText>Reports</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/cases">
              <CaseManagementIcon />
              <SidebarMenuNavText>Case Management</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          {children}
        </SidebarMenuNavList>

        <SidebarMenuNavList>
          <SidebarMenuNavItem>
            <SidebarMenuNavLink exact to="/settings">
              <SettingsIcon />
              <SidebarMenuNavText>Settings</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
        </SidebarMenuNavList>
      </SidebarMenuNav>
    </SidebarWrapper>
  )
}

// Default Props
Sidebar.defaultProps = {
  children: '',
}

// Proptypes Validation
Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default Sidebar
