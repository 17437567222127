import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
`
export const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  gap: 10px;
`

export const Container = styled.div``
export const FlowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-top: 20px;
  gap: 50px;
  padding-bottom: 20px;
`
export const FlowContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  width: 100%;
`

export const FlowDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: 8px;
  max-width: 470px;
`
export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`
export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`
export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: 10px;
`
export const TransferDetailsWrapper = styled.div`
  padding: 20px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(520px, 1fr));
  gap: 60px;
`
export const LineBreak = styled.hr`
  width: 100%;
  height: 2px !important;

  color: ${({ theme }) => theme.colors.gray};
  margin: 20px 0;
`
