import styled from 'styled-components'

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  margin-top: ${({ label }) => (label ? '-5px;' : '20px;')};
  background: ${({ theme }) => theme.colors.white};

  border-radius: ${({ open }) => (open ? '10px 10px 0 0' : '10px')};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07);
`
export const IconWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 12px;
  left: 10px;

  & svg {
    min-width: 20px;
    margin: 0 0 0 10px;
    & > path {
      stroke: ${({ theme }) => theme.colors.darkGray};
    }
  }
`
export const BottomOptionsWrapper = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.colors.lightGray};
  margin: 0;
`
export const BottomOption = styled.div`
  margin: 10px 10px 10px 40px;
  padding: 10px 20px;
`

export const FilterWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;

  // Themed Properties
  background: ${({ theme }) => theme.colors.white};

  & button {
    background-color: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.darkGray};
    border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
    border-radius: 7px;
  }
  & button > svg > path {
    stroke: ${({ theme }) => theme.colors.blue};
  }
  & button > svg > line {
    stroke: ${({ theme }) => theme.colors.blue};
  }
`
export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-height: 35.5px;

  opacity: 1;
  transition: 0.3s;

  ${({ collapseFilter }) => collapseFilter && 'opacity: 0; min-width: 0px; overflow: hidden; width: 0;'};
  & div {
    max-width: 260px;
  }
`

export const Label = styled.label`
  position: relative;
  z-index: 2;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 12px;
  padding: 0 7px;
  transform: translate(15px, 15px);

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`

export const SearchInput = styled.input`
  transition: 0.4s;

  position: relative;

  min-height: 35.5px;
  height: fit-content;
  width: 100%;

  padding: 0 12px 0 50px;

  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  outline: none;
  border: none;

  color: ${({ theme }) => theme.colors.darkGray};
  border-radius: ${({ open }) => (open ? '0 0 10px 10px' : '10px')};

  background-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.colors.white)};

  &:hover {
    box-shadow: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`

export const SelectInputOptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  font-size: 16px;
  border-radius: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};

  background-color: ${({ theme }) => theme.colors.white};
  user-select: none;
  z-index: 2;

  border-top: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.07);
`

export const SelectInputOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;

  color: ${({ theme }) => theme.colors.darkGray};

  cursor: pointer;
  transition: 0.4s;

  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0);

  margin: 0 10px 0 40px;

  &:first-child {
    margin: 10px 10px 0 40px;
  }
  &:last-child {
    margin: 0 10px 10px 40px;
  }
  &:only-child {
    margin: 10px 10px 10px 40px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.lightGray};
  }
`
export const SelectInputText = styled.div`
  font-size: 16px;
  min-width: 40px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const SelectInputSubText = styled.div`
  font-size: 12px;
  min-width: 40px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.gray};
`
