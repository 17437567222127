import styled from 'styled-components'

export const TransferFormWrapper = styled.div`
  height: 100%;
`

export const FormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const FormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 0;
`

export const FormLabel = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-top: 30px;

  &#selected-account {
    margin-bottom: -15px;
  }
`

export const FormInputGroupItem = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 130px;
  align-items: center;
  gap: 10px;
  width: 100%;

  background-color: transparent;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};

  & input {
    background-color: transparent;
    border: none;
    padding: 15px 20px 35px 15px;
    border-radius: 0;
    border-right: 2px solid ${({ theme }) => theme.colors.lightGray};
  }
  & > div > div > div {
    min-width: 130px;
    & > div {
      font-size: 16px;
    }
  }
`
export const SubText = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;

  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
`
