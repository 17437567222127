import styled from 'styled-components'

// Library Components
import { Modal } from 'reactstrap'

export const ModalWrapper = styled(Modal)`
  padding: 0;
  color: ${({ theme }) => theme.colors.darkGray};
  & .modal-content {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
  }
`
