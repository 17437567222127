/* eslint-disable no-confusing-arrow */
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 500px;
  margin-top: 10px;
  margin-bottom: -20px;
`
export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
`
export const LeftGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  margin: 0;
`
export const LeftGroupItem = styled.div`
  font-size: 13px;
  text-align: right;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const LeftGroupItemWrapper = styled.div`
  display: flex;
  min-width: 100px;

  padding: 0 40px 30px 0;
  color: ${({ theme }) => theme.colors.darkGray};
  transform: translateY(5px);
`
export const RightGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  margin: 0;
`
export const RightGroupItem = styled.div`
  text-align: left;
  font-size: 13px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.darkGray)};
  transform: translateY(5px);
`
export const RightGroupItemWrapper = styled.div`
  position: relative;
  min-width: 100px;

  padding: 30px 0 0 40px;
  border-left: 2px solid ${({ theme, active }) => (active ? `${theme.colors.blue}` : `${theme.colors.gray}`)};
  border-left: ${({ theme, failed }) => failed && `2px solid ${theme.colors.red} !important`};
  border-left: ${({ theme, disabled }) => disabled && `2px solid ${theme.colors.gray}50 !important`};
  &:first-child {
    padding: 0 0 0 40px;
    border-left: 2px solid transparent !important;
  }
  ::before {
    content: '.';
    color: transparent;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: -6.5px;
    bottom: 0px;

    background-color: ${({ theme, active }) => (active ? `${theme.colors.blue}` : `${theme.colors.gray}`)};
    background-color: ${({ theme, failed }) => failed && `${theme.colors.red} !important`};
    background-color: ${({ theme, disabled }) => disabled && `${theme.colors.gray}50 !important`};
  }
  ::after {
    content: '.';
    color: transparent;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    left: -14px;
    bottom: -7px;

    background-color: ${({ theme, active }) => (active ? `${theme.colors.blue}4f` : `${theme.colors.gray}4f`)};
    background-color: ${({ theme, failed }) => failed && `${theme.colors.red}4f !important`};
  }
`
