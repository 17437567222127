import styled from 'styled-components'

// Library Components
import { ModalBody } from 'reactstrap'

export const ModalBodyWrapper = styled(ModalBody)`
  padding: 10px 25px;
  max-height: 60vh;
  overflow-y: auto;
`
