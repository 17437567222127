import styled from 'styled-components'

export const FormWrapper = styled.div``

export const FormInputGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div > div {
    margin-bottom: 0 !important;
  }
`
export const FormGroupWrapper = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 10px 60px;
`
export const FormTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 0 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
