import styled from 'styled-components'

export const SelectWrapper = styled.div`
  width: 100%;
  margin-top: ${({ label }) => label && '-5px;'};
`

export const FormGroupWrapper = styled.div`
  position: relative;
  min-height: 48px;
`

export const Label = styled.label`
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 12px;
  padding: 0 8px;
  transform: translate(15px, 15px);

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`

export const SelectInput = styled.input`
  display: none;
`

export const SelectOption = styled.option`
  color: ${({ theme }) => theme.colors.darkGray};
`

export const SelectInputLabel = styled.input`
  color: ${({ theme }) => theme.colors.darkGray};
  background-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.colors.lightGray)};
  transition: 0.4s;

  position: relative;
  // TODO: Use theme functions
  &:after {
    content: '';

    position: absolute;
    top: 42%;
    right: 15px;

    display: ${({ disabled }) => (disabled ? 'none' : 'inline-block')};
    margin-left: 0.255em;
    vertical-align: 0.255em;

    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: ${({ theme }) => theme.colors.darkGray};
  }

  min-height: 54px;
  height: fit-content;
  width: 100%;

  padding: 15px 20px;

  font-size: 16px;
  border-radius: 10px;
  font-weight: 400;
  box-shadow: none;

  border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const SelectInputOptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;

  padding: 10px 15px;
  width: 100%;

  font-size: 14px;
  border-radius: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
  user-select: none;
  z-index: 2;
`

export const SelectInputOptions = styled.div`
  display: flex;
  padding: 10px;

  cursor: pointer;
  transition: 0.4s;

  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;

  &:hover {
    border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
  }
`

export const FormErrorMessage = styled.div`
  position: absolute;
  bottom: -20px;
  left: 5px;
  color: ${({ theme }) => theme.colors.brightRed};
  font-size: 14px;
`

export const Abbreviation = styled.div`
  color: ${({ theme }) => theme.colors.lightGray};
`
