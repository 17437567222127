import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Store
import { actions } from 'core/store'

// Hooks
// import { useLocalStorage } from 'core/hooks/storage'
import { useGetAddresses } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './TransactionsTable.elements'

// Views
import { AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { TransactionsTableItem, AddressTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function TransactionsTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { addressFilters } = filters
  const { isPageTableUpdated } = ui
  const { setIsPageTableUpdated, setAddressFilters, showAlert } = actions

  // Hooks
  const { getAddresses, getAddressesData, getAddressesError, isGetAddressesLoading } = useGetAddresses()

  // Local states
  const [addresses, setAddresses] = useState([])
  const [pagination, setPagination] = useState()

  useEffect(() => getAddresses(addressFilters), [addressFilters])
  useEffect(() => {
    if (isPageTableUpdated) {
      getAddresses(addressFilters)
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])
  useEffect(() => {
    if (getAddressesData) {
      setAddresses(getAddressesData.items)
      setPagination(getAddressesData.pagination)
    }
  }, [getAddressesData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getAddressesError) showAlert({ type: 'error', message: 'An error occured in fetching addresses' })
  }, [getAddressesError])
  return (
    <TableWrapper>
      <AccordionTable
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        filterComponents={<AddressTableFilter />}
        tableFilters={addressFilters}
        setTableFilters={setAddressFilters}
      >
        {addresses.length > 0 && !isGetAddressesLoading ? (
          <>
            {addresses.map((data) => (
              <TransactionsTableItem key={data.id} data={data} />
            ))}
          </>
        ) : (
          <div>
            {isGetAddressesLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <EmptyTablePlaceholder setFilter={setAddressFilters} />
            )}
          </div>
        )}
      </AccordionTable>
    </TableWrapper>
  )
}

// Default Props
TransactionsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
TransactionsTable.propTypes = {
  filters: PropTypes.shape({
    addressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressFilters: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable)
