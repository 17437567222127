import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ModalHeaderWrapper, ModalHeaderTitle, ModalHeaderCloseIcon } from './ModalHeader.elements'

function ModalHeader(props) {
  // Destructure
  const { children, close, toggle, ...rest } = props

  return (
    <ModalHeaderWrapper {...rest}>
      <ModalHeaderTitle>{children}</ModalHeaderTitle>
      <ModalHeaderCloseIcon onClick={toggle}>{close}</ModalHeaderCloseIcon>
    </ModalHeaderWrapper>
  )
}

// Default Props
ModalHeader.defaultProps = {
  children: '',
  close: '',
  toggle: () => {},
}

// Proptypes Validation
ModalHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  close: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  toggle: PropTypes.func,
}

export default ModalHeader
