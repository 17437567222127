/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer, TableText } from './ReportsTableHeader.elements'

function ReportsTableHeader() {
  return (
    <TableButtonWrapper>
      <TableRowWrapper style={{ gridTemplateColumns: '1fr 0.7fr 2fr' }}>
        <TableRowContainer>
          <TableText>Requested At</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Name</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Sections</TableText>
        </TableRowContainer>
      </TableRowWrapper>

      <TableRowWrapper style={{ gridTemplateColumns: '150px 1fr' }}>
        <TableRowContainer style={{ justifyContent: 'center' }}>
          <TableText>Status</TableText>
        </TableRowContainer>

        <TableRowContainer />
      </TableRowWrapper>
    </TableButtonWrapper>
  )
}

// Default Props
ReportsTableHeader.defaultProps = {}

// Proptypes Validation
ReportsTableHeader.propTypes = {}
export default ReportsTableHeader
