import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Constants
import {
  // ADDRESS_REGEX_OPTIONS, ADDRESS_CHAIN_OPTIONS_MAPPING,
  ADDRESS_CHAIN_OPTIONS,
  INTEGRATION_PARTNERS_ID,
} from 'common/constants/formOptions'

// Hooks
import { useCreateOrUpdateAddress, useGetAddressChainRecommendation } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  Wrapper,
  SearchBarWrapper,
  SearchInputWrapper,
  SearchBarContainer,
  Backdrop,
  Title,
  CloseIconWrapper,
  ChainChoiceWrapper,
  ChainChoice,
} from './AddressSearchBar.elements'

// Views
import { TextField } from 'views/components'

// Assets
import { ScanIcon, CloseIcon } from 'assets/images'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AddressSearchBar(props) {
  // props
  const { actions } = props

  // destructure
  const { showAlert, setScannedAddress, toggleInfoModal, setInfoModalDetails } = actions

  // local states
  const [isActive, setIsActive] = useState(false)
  const [addressInput, setAddressInput] = useState('')
  const [chain, setChain] = useState('')
  const [matchedChains, setMatchedChains] = useState([])
  // const [otherChains, setOtherChains] = useState([])

  // hooks
  const {
    createOrUpdateAddress,
    createOrUpdateAddressData,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
  } = useCreateOrUpdateAddress()
  const {
    getAddressChainRecommendation,
    getAddressChainRecommendationData,
    // getAddressChainRecommendationError,
    // isGetAddressChainRecommendationLoading,
  } = useGetAddressChainRecommendation()
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // Functions
  function handleSubmit() {
    createOrUpdateAddress({
      address: addressInput,
      chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
      integration_id: INTEGRATION_PARTNERS_ID[chain],
    })
    setAddressInput('')
    setChain('')
  }
  function handleChainRecommendation(data) {
    const currentMatchedChains = []
    if (data?.length > 0) {
      data.map((item) => {
        currentMatchedChains.push({ disabled: false, value: item, label: item })
      })
    }
    if (currentMatchedChains.length === 1) {
      if (currentMatchedChains[0]?.value === 'near') {
        return setMatchedChains([])
      }
    }
    if (currentMatchedChains.length < 1) return setMatchedChains([])
    if (currentMatchedChains.length > 1) return setMatchedChains(currentMatchedChains)
  }

  // console.log(matchedChains)
  function handleAddressChange(inputValue) {
    setAddressInput(inputValue)
    getAddressChainRecommendation({ address: inputValue, chain })
    // handleChainMatch(inputValue)
  }

  useEffect(() => chain && handleSubmit(), [chain])
  useEffect(() => {
    if (getAddressChainRecommendationData) handleChainRecommendation(getAddressChainRecommendationData)
  }, [getAddressChainRecommendationData])
  useEffect(() => !isActive && setAddressInput(''), [isActive])
  useEffect(() => {
    if (createOrUpdateAddressData) {
      setScannedAddress(createOrUpdateAddressData)
      setIsActive(false)
    }
  }, [createOrUpdateAddressData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (createOrUpdateAddressError) showAlert({ type: 'error', message: 'An error occured in screening address' })
  }, [createOrUpdateAddressError])
  useEffect(() => {
    if (isCreateOrUpdateAddressLoading) {
      toggleInfoModal(true)
      setInfoModalDetails({
        title: 'Scanning Address',
        content: 'This process may take over 60 seconds, depending on the volume of data.',
        loading: true,
      })
    } else {
      toggleInfoModal(false)
      setInfoModalDetails()
    }
  }, [isCreateOrUpdateAddressLoading])
  return (
    <Wrapper>
      <SearchBarWrapper isActive={isActive}>
        <SearchBarContainer isActive={isActive}>
          <SearchInputWrapper isActive={isActive}>
            {isActive && (
              <CloseIconWrapper onClick={() => setIsActive(false)}>
                <CloseIcon />
              </CloseIconWrapper>
            )}
            <ScanIcon />
            <TextField
              placeholder={isCreateOrUpdateAddressLoading ? 'loading...' : !isActive && 'Scan an address hash…'}
              onClick={() => {
                setIsActive(true)
              }}
              value={addressInput}
              onChange={(e) => handleAddressChange(e.currentTarget.value)}
            />
          </SearchInputWrapper>
          {addressInput && isActive && !isCreateOrUpdateAddressLoading && (
            <ChainChoiceWrapper isActive={isActive} key={matchedChains}>
              {matchedChains?.length < 1 && <Title>No chain has been identified for the provided address hash.</Title>}
              {/* {ADDRESS_CHAIN_OPTIONS &&
                !matchedChains &&
                ADDRESS_CHAIN_OPTIONS.map((item) => (
                  <ChainChoice
                    key={item.value}
                    style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                    onClick={() => !item.disabled && setChain(item.value)}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </ChainChoice>
                ))} */}
              {matchedChains &&
                matchedChains.map((item) => (
                  <ChainChoice
                    key={item.value}
                    style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                    onClick={() => !item.disabled && setChain(item.value)}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </ChainChoice>
                ))}
              {/* <Title>Other Chain Options: </Title>
          {otherChains &&
            otherChains.map((item) => (
              <ChainChoice
                key={item.value}
                style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                onClick={() => !item.disabled && setChain(item.value)}
              >
                {item.label}
              </ChainChoice>
            ))} */}
            </ChainChoiceWrapper>
          )}
        </SearchBarContainer>
      </SearchBarWrapper>
      <Backdrop
        onClick={() => setIsActive(false)}
        style={{ zIndex: isActive ? '5' : '-1', opacity: isActive ? '0.5' : '0' }}
      />
    </Wrapper>
  )
}

// Default Props
AddressSearchBar.defaultProps = {
  actions: {},
}

// Proptypes Validation
AddressSearchBar.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setScannedAddress: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSearchBar)
