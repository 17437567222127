import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Views
import { Container } from 'views/components'

import {
  App,
  Header,
  Sidebar,
  Content,
  PageWrapper,
  BlockchainAnalyticsSubHeader,
  TransactionsTable,
  TransactionsSearchBar,
  NoAccess,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters, ui }) => ({ filters, ui })
const mapDispatchToProps = actions

function Transactions() {
  return (
    <App>
      <Sidebar />
      <Header subHeader={<BlockchainAnalyticsSubHeader />} pageTitle="Blockchain Analytics" />
      <NoAccess />
      {/* <Content>
        <PageWrapper>
          <Container>
            <TransactionsSearchBar />
            <TransactionsTable />
          </Container>
        </PageWrapper>
      </Content> */}
    </App>
  )
}

// Default Props
Transactions.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
Transactions.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
