import styled from 'styled-components'

export const Wrapper = styled.div``
export const SearchBarWrapper = styled.div`
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.darkGray};
  // Themed Properties
`

export const SearchBarContainer = styled.div`
  padding-top: 20px;
  z-index: 7;
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  // Themed Properties
`

export const SearchInputWrapper = styled.div`
  z-index: ${({ isActive }) => (isActive ? 7 : 0)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.lightGray : theme.colors.white)};
  border-radius: 10px;
  padding: 5px 0 5px 20px;
  box-shadow: ${({ isActive }) => !isActive && '0px 0px 7px rgba(0, 0, 0, 0.07)'};
  & input {
    background: ${({ theme, isActive }) => (isActive ? theme.colors.lightGray : theme.colors.white)};
    border: none;
    padding: 15px 20px 15px 15px;
  }
  & svg {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
  & svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => `${theme.colors.white}`};
  transition: 0.3s;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: -60px;
  cursor: pointer;
  & svg {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
  & svg > path {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
`
export const ChainChoiceWrapper = styled.div`
  z-index: ${({ isActive }) => (isActive ? 6 : 0)};
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 10px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07);
  max-height: 400px;
  overflow-y: auto;
`
export const ChainChoice = styled.div`
  cursor: pointer;
  font-weight: 300;
  padding: 5px 10px;
  border-radius: 7px;

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.lightGray};
  }
`
export const Title = styled.h2`
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 15px 0 5px 0;
  color: ${({ theme }) => theme.colors.darkGray};
`
