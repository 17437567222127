import styled from 'styled-components'

export const AccordionItem = styled.div`
  margin-bottom: 5px;
`
export const AccordionItemHeading = styled.div`
  cursor: pointer;
`
export const AccordionItemButtonWrapper = styled.div`
  position: relative;
  padding: 5px 20px;
  transition: all 0.3s;
  -webkit-font-smoothing: antialiased;

  // TODO: Use theme functions
  &:focus {
    outline: none;
  }

  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.darkGray};
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  ${({ expanded }) => expanded && 'border-radius: 10px 10px 0 0'};

  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07);
  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  }
`

export const AccordionItemButtonInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const AccordionItemButtonInner = styled.div`
  flex: 1;
  padding: 0;
  font-size: 15px;
  font-weight: 100;
`

export const AccordionItemButtonExpandIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;

  transition: all 0.3s;
  & svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }

  // Conditional Properties
  ${({ withExpandIcon }) => !withExpandIcon && 'display: none;'}
  ${({ expanded }) => expanded && 'transform: rotate(180deg) translateY(50%);'};
`

export const AccordionItemPanelWrapper = styled.div`
  padding: 0 20px;
  height: 0px;
  overflow: hidden;

  transition: all 0.3s;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-top: none;
  border-bottom: none;
  border-radius: 0 0 10px 10px;

  ${({ expanded }) => expanded && 'height: auto; padding: 20px;'}
  ${({ expanded, theme }) => expanded && `border-bottom: 1px solid ${theme.colors.lightGray};`}
`
