import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TransferFormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormWrapper,
  FormLabel,
  OptionLabel,
} from './RecipientTransferForm.elements'

// Hooks
import { useSearchRecipient, useFetchRecipient } from 'core/hooks/api'

// Views
import { TextField, Button, Search } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function RecipientTransferForm(props) {
  // Destructure
  const { formData, setFormData, actions } = props

  // Store Actions
  const {
    setIsIndividualRecipientDrawerOpen,
    setIsEntityRecipientDrawerOpen,
    setShowHeaderLoader,
    setToBeUpdatedRecipient,
  } = actions

  // hooks
  const { searchRecipient, recipientSearchData, isRecipientSearchLoading } = useSearchRecipient()
  const { getRecipient, recipientData, isRecipientLoading } = useFetchRecipient()

  // Internal State
  const [searchResults, setSearchResults] = useState([])

  // functions
  const handleTransactionChange = (value) => {
    setFormData({ ...formData, transfer: { ...formData.transfer, ...value } })
  }
  const handleRecipientSearch = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value) return searchRecipient({ search_term: value })
  }, 500)
  const handleOnAutoCompleteSelect = (value) => {
    getRecipient({ recipient_id: value })
  }

  // useEffects
  useEffect(() => {
    if (recipientSearchData) {
      setSearchResults(() =>
        recipientSearchData.map(({ external_id, display_name, recipient_id }) => ({
          label: external_id,
          subLabel: display_name,
          value: recipient_id,
        }))
      )
    }
    if (!recipientSearchData) setSearchResults([])
  }, [recipientSearchData])
  useEffect(() => {
    if (recipientData) {
      setFormData({ ...formData, recipient: { ...formData.recipient, ...recipientData } })
    }
  }, [recipientData])
  useEffect(() => {
    if (isRecipientLoading) {
      setShowHeaderLoader(true)
      setFormData({ ...formData, recipient: null })
    }
    if (!isRecipientLoading) setShowHeaderLoader(false)
  }, [isRecipientLoading])
  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormInputGroupItem>
            <TextField
              placeholder="Address Hash"
              label="Enter address"
              onChange={(e) => {
                handleTransactionChange({ recipient_hash_address: e.target.value })
              }}
              value={formData.transfer.recipient_hash_address}
            />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <Search
              placeholder="Search for recipient"
              onInputChange={handleRecipientSearch}
              options={searchResults}
              setOptions={setSearchResults}
              onSelect={handleOnAutoCompleteSelect}
              isLoading={isRecipientSearchLoading}
              bottomOptions={
                <FormGroupWrapper>
                  <OptionLabel>Create New Recipient</OptionLabel>
                  <FormInputGroupItem>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setToBeUpdatedRecipient(null)
                        setIsIndividualRecipientDrawerOpen(true)
                      }}
                    >
                      Individual
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setToBeUpdatedRecipient(null)
                        setIsEntityRecipientDrawerOpen(true)
                      }}
                    >
                      Business
                    </Button>
                  </FormInputGroupItem>
                </FormGroupWrapper>
              }
            />
          </FormInputGroupItem>

          {formData.recipient && !isRecipientLoading && (
            <>
              <FormLabel>Selected Recipient</FormLabel>
              <FormInputGroupItem>
                <TextField label="External ID" disabled value={formData.recipient.external_id} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Recipient Type" disabled value={formData.recipient.recipient_type_desc} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Name" disabled value={formData.recipient.display_name} />
              </FormInputGroupItem>
            </>
          )}
          {isRecipientLoading && <FormLabel>Loading...</FormLabel>}
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
RecipientTransferForm.defaultProps = {
  formData: {},
  setFormData: () => {},
  actions: {},
  form: {},
}

// Proptypes Validation
RecipientTransferForm.propTypes = {
  formData: PropTypes.shape({
    transfer: PropTypes.shape({ recipient_hash_address: PropTypes.string }),
    recipient: PropTypes.shape({
      external_id: PropTypes.string,
      recipient_type_desc: PropTypes.string,
      display_name: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdRecipient: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientTransferForm)
