import styled from 'styled-components'

export const TableButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  gap: 50px;
  align-items: center;

  padding: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TableRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 20px;

  &:last-child > div:last-child {
    max-width: 150px;
  }
  &:last-child > div:first-child {
    justify-content: flex-end;
    max-width: 550px;
  }

  &:first-child > div:last-child {
    justify-content: flex-end;
    visibility: ${({ isExpanded }) => isExpanded && 'hidden'};
  }
  &:last-child > div:first-child {
    visibility: ${({ isExpanded }) => isExpanded && 'hidden'};
  }
`
export const TableRowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  gap: 20px;
`
export const TableColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableText = styled.div`
  font-size: 15px;
  font-weight: 400;

  max-width: 250px;

  &:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
export const AddressHashWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  & svg {
    height: 20px;
    width: 20px;
  }
`
export const AddressHash = styled.div`
  font-size: 15px;
  font-weight: 400;
`

export const CopyNotification = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 10px;

  font-size: 15px;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};

  transition: 0.1s;
  opacity: ${({ display }) => (display ? 1 : 0)};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
`

export const TableTextTiny = styled.span`
  font-size: 8px;
  font-weight: 400;
`
export const TableSubText = styled.div`
  font-size: 10px;
  font-weight: 400;
`

export const TablePanelWrapper = styled.div``
