import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 600px;

  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const BodyOverflowWrapper = styled.div``

export const BodyWrapper = styled.div`
  // Conditional Properties
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
`

export const HeaderFilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -20px;
`

export const HeaderOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`
export const BodyChildrenWrapper = styled.div``
