// import { authAxios } from 'common/utils/axios'
import { ADDRESS_ENDPOINTS } from '../../common/constants/apiUrls'

import {
  // LOCAL_URL,
  BASE_URL,
} from 'common/constants/apiUrls'
import axios from 'axios'

const {
  CREATE_OR_UPDATE_ADDRESS,
  LINK_ADDRESS,
  DELETE_ADDRESS,
  GET_ADDRESSES,
  GET_ADDRESS_DETAILS,
  GET_ADDRESS_HISTORY,
  POST_ADDRESS_REPORT,
  GET_ADDRESS_VERIFICATION,
  GET_ADDRESS_CHAIN_RECOMMENDATION,
} = ADDRESS_ENDPOINTS

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const newAuthAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const CreateOrUpdateAddress = async ({
  address,
  chain,
  type,
  integration_id,
  link_id,
  name_first,
  name_last,
}) => {
  const response = await newAuthAxios.post(CREATE_OR_UPDATE_ADDRESS, {
    address,
    chain,
    type,
    integration_id,
    link_id,
    name_first,
    name_last,
  })

  const { data } = response.data

  return data
}
export const LinkAddress = async ({ link_id, link_type, address_id }) => {
  const response = await newAuthAxios.patch(`${LINK_ADDRESS}/${address_id}/link`, {
    link_id,
    link_type,
  })

  const { data } = response.data

  return data
}
export const DeleteAddress = async ({ address_id }) => {
  const response = await newAuthAxios.patch(`${DELETE_ADDRESS}/${address_id}`)

  const { data } = response.data

  return data
}

export const GetAddresses = async ({ start_range, end_range, page, size, sort }) => {
  const response = await newAuthAxios.get(GET_ADDRESSES, {
    params: {
      start_range: start_range && start_range,
      end_range: end_range && end_range,
      page: page && page,
      size: size && size,
      sort: sort && sort,
    },
  })

  const { data } = response.data

  return data
}
export const GetAddressDetails = async ({ address_id, start_range, end_range, page, size, sort }) => {
  const response = await newAuthAxios.get(`${GET_ADDRESS_DETAILS}/${address_id}`, {
    params: {
      start_range: start_range && start_range,
      end_range: end_range && end_range,
      page: page && page,
      size: size && size,
      sort: sort && sort,
    },
  })

  const { data } = response.data

  return data
}
export const GetAddressHistory = async ({ address_id, start_range, end_range, page, size, sort }) => {
  const response = await newAuthAxios.get(`${GET_ADDRESS_HISTORY}/${address_id}/history`, {
    params: {
      start_range: start_range && start_range,
      end_range: end_range && end_range,
      page: page && page,
      size: size && size,
      sort: sort && sort,
    },
  })

  const { data } = response.data

  return data
}
// !UNSURE IF THIS IS RIGHT
export const PostAddressReport = async ({ address_id, sections, start_range, end_range }) => {
  const response = await newAuthAxios.post(
    `${POST_ADDRESS_REPORT}/${address_id}/reports?sections=${sections.join(
      ','
    )}&start_range=${start_range}&end_range=${end_range}`
  )

  const { data } = response.data

  return data
}

export const GetAddressVerification = async ({ address, chain }) => {
  const response = await newAuthAxios.get(`${GET_ADDRESS_VERIFICATION}/${address}/chains/${chain}`)

  const { data } = response.data

  return data
}

export const GetAddressChainRecommendation = async ({ address }) => {
  const response = await newAuthAxios.get(`${GET_ADDRESS_CHAIN_RECOMMENDATION}/${address}/chain_discovery`)

  const { data } = response.data

  return data
}
